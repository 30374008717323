import { useMemo } from 'react'
import type { DefaultValues, FieldValues } from 'react-hook-form'
import type { AnyObjectSchema, InferType } from 'yup'

import type {
  FormComponentSchema,
  FormSchema
} from '../types/schema'
import type {
  FormCustomContext,
  InferPropsMap,
  ComponentMapBase,
  ComponentSchemaPropsMapBase,
} from '../types/schema-base'


const indexArrayPartBlocks = <
  TFieldValues extends FieldValues,
  CPropsMap extends Partial<ComponentSchemaPropsMapBase> = ComponentSchemaPropsMapBase,
  TContext extends FormCustomContext = FormCustomContext,
  TFormComponentSchema extends FormComponentSchema<TFieldValues, CPropsMap, TContext> = FormComponentSchema<TFieldValues, CPropsMap, TContext>,
>(
  parentName: string,
  blocks: TFormComponentSchema[],
): Record<string, TFormComponentSchema> => {
  let index: Record<string, TFormComponentSchema> = {}

  for (const block of blocks) {
    if ('_components' in block && block._components) {
      index = Object.assign(index, indexBlocks<TFieldValues, CPropsMap, TContext>(block._components))
    }

    if ('parts' in block && block.parts) {
      index = Object.assign(index, indexArrayPartBlocks<TFieldValues, CPropsMap, TContext>(`${parentName}.${block.name}`, block.parts as FormComponentSchema<TFieldValues, CPropsMap, TContext>[]))
    }

    index[`${parentName}.${block.name}`] = block

  }

  return index
}

const indexBlocks = <
  TFieldValues extends FieldValues,
  CPropsMap extends Partial<ComponentSchemaPropsMapBase> = ComponentSchemaPropsMapBase,
  TContext extends FormCustomContext = FormCustomContext,
  TFormComponentSchema extends FormComponentSchema<TFieldValues, CPropsMap, TContext> = FormComponentSchema<TFieldValues, CPropsMap, TContext>,
>(
  blocks: TFormComponentSchema[],
): Record<string, TFormComponentSchema> => {
  let index: Record<string, TFormComponentSchema> = {}

  for (const block of blocks) {
    if ('_components' in block && block._components) {
      index = Object.assign(index, indexBlocks<TFieldValues, CPropsMap, TContext>(block._components))
    }

    if ('parts' in block && block.parts) {
      index = Object.assign(index, indexArrayPartBlocks<TFieldValues, CPropsMap, TContext>(block.name, block.parts as FormComponentSchema<TFieldValues, CPropsMap, TContext>[]))
    }

    index[block.name] = block

  }

  return index
}

export default function useParseSchema<
  TValidationSchema extends AnyObjectSchema,
  TVisibilitySchema extends AnyObjectSchema,
  TFieldValues extends FieldValues = InferType<TValidationSchema>,
  TComponentMap extends Partial<ComponentMapBase> = ComponentMapBase,
  TContext extends FormCustomContext = FormCustomContext,
  TComponentPropMaps extends Partial<ComponentSchemaPropsMapBase> = InferPropsMap<TComponentMap>,
>(
  schema: Omit<FormSchema<TValidationSchema, TVisibilitySchema, TComponentMap, TContext, TFieldValues>, 'validationSchema'>,
  defaultValues?: DefaultValues<TFieldValues>,
) {
  return useMemo(() => {
    const { blocks, transforms, ...formProps } = schema

    const blockIndex = indexBlocks<TFieldValues, TComponentPropMaps, TContext>(blocks)

    if (!defaultValues && !formProps.defaultValues) {
      throw new Error('defaultValues must be provided via props or schema')
    }

    let _defaultValues = { ...defaultValues, ...formProps.defaultValues } as Record<
      keyof DefaultValues<TFieldValues>,
      unknown
    >

    if (_defaultValues && transforms) {
      _defaultValues = Object.entries(_defaultValues).reduce(
        (acc, [key, value]: [keyof DefaultValues<TFieldValues>, unknown]) => {
          const fieldType = blockIndex[key].type

          if (fieldType in transforms) {
            acc[key] = transforms[fieldType]?.(value, 'in')
          } else {
            acc[key] = value
          }

          return acc
        },
        _defaultValues,
      )
    }

    return {
      blocks,
      blockIndex,
      formProps: {
        ...formProps,
        defaultValues: _defaultValues as DefaultValues<TFieldValues>,
      },
    }
  }, [defaultValues, schema])
}
