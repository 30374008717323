type Config = { [key: string]: string }

type Environment = 'staging' | 'integration'

export const ACRE_ENV: Environment = 'staging'

const envConfigs: Record<Environment, Record<string, string>> = {
  staging: {
    ENV: 'staging',
    API_BASE_URL: 'https://api.acreplatforms.co.uk',
    API1_URL: 'https://api.acreplatforms.co.uk/v1',
    API_URL: 'https://api.acreplatforms.co.uk/v1/acre',
    CLIENT_PORTAL_API_BASE_URL: 'https://clientapi.acreplatforms.co.uk',
    COOKIE_DOMAIN: '.acreplatforms.co.uk',
    CLIENT_PORTAL_COOKIE_DOMAIN: 'clientapi.acreplatforms.co.uk',
    CLIENT_PORTAL_API_KEY: '41478a89646604b7455e036aca8f31671863b219',
    CLIENT_ORIGINATOR: 'ea6b334b-1282-437f-ad82-613c5172f882',
    CONTENTFUL_API_KEY: 'BZa30UtA_8RAgFnsD7GLWDHfq2MiIF0MQNf5Ek8AlDg',
    AUTH_URL: 'https://acresoftware.b2clogin.com',
    INTRODUCER_PORTAL_URL: 'https://introducers.staging.acreplatforms.co.uk',
    ACRE_SUPPORT_ORG_ID: '9dea9381-7771-4caa-aa8d-6e4d86219fd4',
    NOTIFICATIONS_URL: 'wss://api.acreplatforms.co.uk/v1/acre/notifications/ws/subscribe',
    LUTHER_TEST_API_KEY: '2e8883dafc6d183fa3e3ee04cc2b10342f3c05b0',
  },
  integration: {
    ENV: 'integration',
    API_BASE_URL: 'https://api.acre.software',
    API1_URL: 'https://api.acre.software/v1',
    API_URL: 'https://api.acre.software/v1/acre',
    CLIENT_PORTAL_API_BASE_URL: 'https://clientapi.acre.software',
    COOKIE_DOMAIN: '.acre.software',
    CLIENT_PORTAL_COOKIE_DOMAIN: 'clientapi.acre.software',
    CLIENT_PORTAL_API_KEY: '58b1250ed3d0e904a8fb87679feda7d2b2226fad',
    CLIENT_ORIGINATOR: 'ea6b334b-1282-437f-ad82-613c5172f882',
    CONTENTFUL_API_KEY: 'BZa30UtA_8RAgFnsD7GLWDHfq2MiIF0MQNf5Ek8AlDg',
    AUTH_URL: 'https://devacresoftware.b2clogin.com',
    INTRODUCER_PORTAL_URL: 'https://introducers.integration.acre.software',
    ACRE_SUPPORT_ORG_ID: '1bfe79ed-503a-4493-88b3-7ffe96ddb456',
    NOTIFICATIONS_URL: 'wss://api.acre.software/v1/acre/notifications/ws/subscribe',
    LUTHER_TEST_API_KEY: '6886e0319a04bde00aa5af89cc9f7368d26d429e',
  },
}

const envConfig = envConfigs[ACRE_ENV]

const config: Config = (window as any).acreEnv || {}
// Remember when adding configs to make sure you have considered any required changes to generateAppConfig.sh & .gitlab-ci.yml & K8 config repo
export default {
  ENV: config.ENV || envConfig.ENV,
  API_BASE_URL: config.API_BASE_URL || envConfig.API_BASE_URL,
  CLIENT_PORTAL_API_BASE_URL: config.CLIENT_PORTAL_API_BASE_URL || envConfig.CLIENT_PORTAL_API_BASE_URL,

  CLIENT_PORTAL_API_URL: config.CLIENT_PORTAL_API_URL || `${envConfig.CLIENT_PORTAL_API_BASE_URL}/v1`,
  CLIENT_PORTAL_OATHKEEPER_API_URL:
    config.CLIENT_PORTAL_OATHKEEPER_API_URL || `${envConfig.CLIENT_PORTAL_API_BASE_URL}/oathkeeper/v1`,
  CLIENT_PORTAL_OATHKEEPER_PUB_API_URL:
    config.CLIENT_PORTAL_OATHKEEPER_PUB_API_URL || `${envConfig.CLIENT_PORTAL_API_BASE_URL}/oathkeeper/pub/v1`,
  CLIENT_PORTAL_COOKIE_DOMAIN: config.CLIENT_PORTAL_COOKIE_DOMAIN || envConfig.CLIENT_PORTAL_COOKIE_DOMAIN,
  ADDRESS_LOOKUP_API_TOKEN: config.ADDRESS_LOOKUP_API_TOKEN || 'PCWVM-5MZ4Z-GBQVY-ZY5PS',
  ADDRESS_LOOKUP_API_URL: config.ADDRESS_LOOKUP_API_URL
    ? config.ADDRESS_LOOKUP_API_URL
    : config.API1_URL
      ? `${config.API1_URL}/acre/propertydata`
      : `${envConfig.API_URL}/propertydata`,
  API_KEY: config.API_KEY || '95thesesc866aae7d64b9b63da48d804156ab71b',
  CLIENT_PORTAL_API_KEY: config.CLIENT_PORTAL_API_KEY || envConfig.CLIENT_PORTAL_API_KEY,
  API_URL: config.API_URL || `${envConfig.API_BASE_URL}/v1/acre`,
  API1_URL: config.API1_URL || `${envConfig.API_BASE_URL}/v1`,
  COOKIE_DOMAIN: config.COOKIE_DOMAIN || envConfig.COOKIE_DOMAIN,
  FULLSTORY_ORG_ID: config.FULLSTORY_ORG_ID || 'QZVRC',
  GLOBAL_FEATURE_FLAGS: config.GLOBAL_FEATURE_FLAGS || '',
  // Note the 'v2' in the GI URL - this is a new API
  GENERAL_INSURANCE_API_URL: config.GENERAL_INSURANCE_API_URL || `${envConfig.API_BASE_URL}/v2/acre/general-insurance`,
  GENERAL_INSURANCE_RSA_PUBLIC_KEY_URL:
    config.GENERAL_INSURANCE_RSA_PUBLIC_KEY_URL ||
    `https://files.staging.myac.re/discovery/rsa-public-key/general-insurance/${ACRE_ENV}_kms.pub`,
  JWT_DOMAIN: config.JWT_DOMAIN || 'https://localhost.myac.re:9001/json',
  LOGOUT_URL: config.LOGOUT_URL || `${envConfig.API1_URL}/user/logout`,
  CLIENT_PORTAL_LOGOUT_URL:
    config.CLIENT_PORTAL_LOGOUT_URL || `${envConfig.CLIENT_PORTAL_API_BASE_URL}/v1/client/logout`,
  INTRODUCER_PORTAL_URL: config.INTRODUCER_PORTAL_URL || envConfig.INTRODUCER_PORTAL_URL,
  PROTECTION_API_URL: config.PROTECTION_API_URL || `${envConfig.API_URL}/protection`,
  PROTECTION_RSA_PUBLIC_KEY_URL:
    config.PROTECTION_RSA_PUBLIC_KEY_URL ||
    `https://files.staging.myac.re/discovery/rsa-public-key/protection/${ACRE_ENV}_kms.pub`,
  REFRESH_URL: config.REFRESH_URL || `${envConfig.API1_URL}/user/refresh`,
  SEARCH_API_URL: config.API1_URL
    ? `${config.API1_URL.replace(/v1/, 'v2')}/acre/search/` // TODO this is only necessary til we fully release this
    : `${envConfig.API_BASE_URL}/v2/acre/search/`,
  SENTRY_ENVIRONMENT: config.SENTRY_ENVIRONMENT || 'localhost.myac.re',
  SWAGGER_API_URL: `${envConfig.API_BASE_URL}/swagger.json`,
  SWAGGER_CLIENT_API_URL: `${envConfig.CLIENT_PORTAL_API_BASE_URL}/client_swagger.json`,
  SWAGGER_AUTH_API_URL: `${envConfig.API_URL}/authentication/swagger/doc.json`,
  CH_API_URL: config.API1_URL ? `${config.API1_URL}/acre/companies-house` : `${envConfig.API_URL}/companies-house`,
  PROPERTY_INFO_API_URL: config.PROPERTY_INFO_API_URL || `${envConfig.API_URL}/propertydata`,
  MAP_SERVER_API_URL: config.MAP_SERVER_API_URL || `${envConfig.API_BASE_URL}/geoserver`,
  // This is used to determine the env - local points to staging
  CLIENT_ORIGINATOR: config.CLIENT_ORIGINATOR || envConfig.CLIENT_ORIGINATOR,
  MATOMO_BASE_URL: config.MATOMO_BASE_URL || 'https://analytics.acreplatforms.co.uk/',
  MATOMO_SITE_ID: config.MATOMO_SITE_ID || '1',
  // This is the integration ID, change to staging when available
  ACRE_SUPPORT_ORG_ID: config.ACRE_SUPPORT_ORG_ID || envConfig.ACRE_SUPPORT_ORG_ID,
  NOTIFICATIONS_URL: config.NOTIFICATIONS_URL || envConfig.NOTIFICATIONS_URL,
  MIXPANEL_TOKEN: config.MIXPANEL_TOKEN || 'dc6ff9779655a9300019b1df14710a7e',
  ZAPIER_URL:
    config.ZAPIER_URL || 'https://zapier.com/developer/public-invite/161225/b84afeca11e61c2a55e466c187ea79f0/',
  OAUTH_PROVIDER: config.OAUTH_PROVIDER || 'https://oauth.acreplatforms.co.uk/',
  AUTH_URL: config.AUTH_URL || envConfig.AUTH_URL,
  ORY_SDK_URL: config.ORY_SDK_URL || `${envConfig.CLIENT_PORTAL_API_BASE_URL}/oathkeeper/v1/authentication`,
  ORY_KRATOS_URL: config.ORY_KRATOS_URL || `${envConfig.CLIENT_PORTAL_API_BASE_URL}/oathkeeper/.ory/kratos/public`,
  AUTH_ID: config.AUTH_ID || 'e0dd1d84-d4ec-4ced-ac1e-0b87e83cdd10',
  CONTENTFUL_API_KEY: config.CONTENTFUL_API_KEY || envConfig.CONTENTFUL_API_KEY,
  CONTENTFUL_SPACES_ID: config.CONTENTFUL_SPACES_ID || 'e3686ogi99km',
  LUTHER_TEST_API_KEY: config.LUTHER_TEST_API_KEY || envConfig.LUTHER_TEST_API_KEY,
  UNPUBLISHED_TEMPLATES_LAST_UPDATED: config.UNPUBLISHED_TEMPLATES_LAST_UPDATED || '2024-08-22T12:32:36.476Z',
  UNPUBLISHED_SR_TEMPLATE_LAST_UPDATED: config.UNPUBLISHED_SR_TEMPLATE_LAST_UPDATED || '2024-09-19T00:00:00.000Z',
}
