import React, { useCallback } from 'react'
import { AutocompleteFreeSoloValueMapping, AutocompleteValue, ChipTypeMap } from '@mui/material'
import type { AnyObjectSchema } from 'yup'
import { AutocompleteField, AutocompleteFieldProps, Chip } from '@acre/design-system'
import { useController, Path, FieldValues } from 'react-hook-form'

// import { RenderErrorMessage } from '../../common/ErrorMessageDisplay'
import { useFieldValidationSchema, FieldBaseProps, useFormApi, useFormContext } from '@acre/forms'
import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'
// import { ErrorMessage } from '@acre/forms'

export interface AutocompleteFieldRHFProps<
  Option extends { label: string; value: string | number },
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
  QueryVariables extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryResults extends Record<string, unknown> = Record<string, unknown>,
  T extends FieldValues = FieldValues,
> extends FieldBaseProps,
    AutocompleteFieldProps<
      Option,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent,
      QueryVariables,
      MoreQueryVariables,
      QueryResults,
      MoreQueryResults
    > {
  name: Path<T>
}

function _AutocompleteFieldRHF<
  Option extends { label: string; value: string | number },
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
  QueryVariables extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryVariables extends Record<string, unknown> = Record<string, unknown>,
  QueryResults extends Record<string, unknown> = Record<string, unknown>,
  MoreQueryResults extends Record<string, unknown> = Record<string, unknown>,
  T extends FieldValues = FieldValues,
  S extends AnyObjectSchema = AnyObjectSchema,
>(
  props: AutocompleteFieldRHFProps<
    Option,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent,
    QueryVariables,
    MoreQueryVariables,
    QueryResults,
    MoreQueryResults,
    T
  >,
) {
  const { name, shouldUnregister = true, getOptions: getOptionsProp, ...other } = props

  const { control } = useFormContext<T>()
  const {
    field,
    fieldState: { error: fieldStateError },
  } = useController<T>({ name, control, shouldUnregister })

  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const { parseText } = useFormApi()

  const { error, helperText, label } = getCommonTranslatedProps(props, { error: fieldStateError, parseText })

  const getOptions = useCallback(
    (data: Option[] | QueryResults | MoreQueryResults) => getOptionsProp?.(data) ?? (data as Option[]),
    [getOptionsProp],
  )

  const handleChange = useCallback<
    Required<AutocompleteFieldProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>>['onChange']
  >(
    (event, value) => {
      if (!value) {
        field.onChange(null)
      } else if (props.freeSolo) {
        field.onChange(value)
      } else if (Array.isArray(value)) {
        field.onChange(value.map((item) => (item as Option).value))
      } else {
        field.onChange((value as Option).value)
      }
    },
    [field, props.freeSolo],
  )

  const getOptionLabel = useCallback(
    (option: Option | AutocompleteFreeSoloValueMapping<FreeSolo>) =>
      typeof option === 'string' || typeof option === 'number' ? option : parseText?.(option.label) || option.label,
    [parseText],
  )

  return (
    <AutocompleteField<
      Option,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent,
      QueryVariables,
      MoreQueryVariables,
      QueryResults,
      MoreQueryResults
    >
      {...other}
      onChange={handleChange}
      value={field.value as AutocompleteValue<Option, Multiple, DisableClearable, FreeSolo>}
      onBlur={field.onBlur}
      name={field.name}
      getOptions={getOptions}
      getOptionLabel={getOptionLabel}
      TextFieldProps={{
        inputRef: field.ref,
        required: !fieldSchema?.spec?.optional,
      }}
      label={label}
      helperText={helperText}
      error={error}
      ErrorTextProps={{ slot: 'div' }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index })

          return (
            <Chip
              key={key}
              label={parseText ? parseText(option.label) : option.label}
              {...tagProps}
              size={props.size === 'large' ? 'medium' : 'small'}
            />
          )
        })
      }
    />
  )
}

const AutocompleteFieldRHF = React.memo(_AutocompleteFieldRHF) as typeof _AutocompleteFieldRHF

export default AutocompleteFieldRHF
