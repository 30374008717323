import { memo } from 'react'

import Button from '../ButtonNewest/Button'

export interface AutocompleteFieldMultiControlsProps {
  optionCount: number
  selectedCount: number
  ownerId?: string
  onClearAll: () => void
  onSelectAll: () => void
  clearAllLabel?: React.ReactNode
  selectAllLabel?: React.ReactNode
}

const DEFAULT_CLEAR_ALL_LABEL = 'Clear all'
const DEFAULT_SELECT_ALL_LABEL = 'Select all'

const _AutocompleteFieldMultiControls = (props: AutocompleteFieldMultiControlsProps) => {
  const {
    ownerId,
    selectedCount = 0,
    optionCount = 0,
    onClearAll,
    onSelectAll,
    clearAllLabel = DEFAULT_CLEAR_ALL_LABEL,
    selectAllLabel = DEFAULT_SELECT_ALL_LABEL,
  } = props

  return (
    <div style={{ display: 'flex', margin: '.5rem 1rem .3rem 1rem' }} aria-controls={ownerId}>
      <Button
        variant="text"
        sx={{ mr: 2, fontSize: '1.1em' }}
        disabled={Boolean(selectedCount === optionCount)}
        onClick={onSelectAll}
      >
        {selectAllLabel}
      </Button>
      <Button variant="text" sx={{ fontSize: '1.1em' }} disabled={!selectedCount} onClick={onClearAll}>
        {clearAllLabel}
      </Button>
    </div>
  )
}

const AutocompleteFieldMultiControls = memo(_AutocompleteFieldMultiControls) as typeof _AutocompleteFieldMultiControls
export default AutocompleteFieldMultiControls
