import React, { useCallback, useRef } from 'react'
import { DialogActions, DialogContent, styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'
import type { FieldValues, UseFormReturn } from 'react-hook-form'
import type { AnyObjectSchema, InferType } from 'yup'

import { Dialog, DialogProps } from '@acre/design-system'
import { ComponentMapBase, Form, FormCustomContext, FormProps } from '@acre/forms'

import { getModalFormUtilityClass } from './modalFormClasses'

export interface ModalFormProps<
  TValidationSchema extends AnyObjectSchema,
  TVisibilitySchema extends AnyObjectSchema,
  CMap extends ComponentMapBase = ComponentMapBase,
  TContext extends FormCustomContext = FormCustomContext,
> extends FormProps<TValidationSchema, TVisibilitySchema, CMap, TContext> {
  classes?: Record<string, string>
  className?: string
  open?: boolean
  loading?: boolean
  title?: React.ReactNode
  disableCloseOnSubmit?: boolean
  actions: React.ReactElement
  onClose?: DialogProps['onClose']
  DialogProps?: Omit<DialogProps, 'title' | 'onSubmit' | 'variant' | 'onError' | 'onClose' | 'open'>
}

const ModalFormRoot = styled(Dialog, {
  name: 'ModalForm',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})``

const ModalFormContent = styled(DialogContent, {
  name: 'ModalForm',
  slot: 'Content',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  padding: `${theme.spacing(2)} !important`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const useUtilityClasses = (ownerState: Partial<ModalFormProps<AnyObjectSchema, AnyObjectSchema>>) => {
  const slots = {
    root: ['root'],
    content: ['content'],
    actions: ['actions'],
  }

  return unstable_composeClasses(slots, getModalFormUtilityClass, ownerState.classes)
}

function _ModalForm<
  TValidationSchema extends AnyObjectSchema,
  TVisibilitySchema extends AnyObjectSchema,
  CMap extends ComponentMapBase = ComponentMapBase,
  TContext extends FormCustomContext = FormCustomContext,
  TFieldValues extends FieldValues = InferType<TValidationSchema>,
>(props: ModalFormProps<TValidationSchema, TVisibilitySchema, CMap, TContext>) {
  const {
    className,
    classes,
    children,
    actions,
    disableCloseOnSubmit = false,
    title,
    loading,
    onSubmit,
    onCancel,
    onClose,
    DialogProps,
    open,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes })

  const handleClose = useCallback<Required<DialogProps>['onClose']>(
    (options, reason) => {
      if (reason !== 'backdropClick') {
        onClose?.(options, reason)
        formApiRef.current?.reset()
      }
    },
    [onClose],
  )

  const handleSubmit = useCallback<FormProps<TValidationSchema, TVisibilitySchema, CMap, TContext>['onSubmit']>(
    async (values, event) => {
      await onSubmit(values, event)

      if (!disableCloseOnSubmit && typeof onClose === 'function') {
        onClose({}, 'escapeKeyDown')
      }
    },
    [disableCloseOnSubmit, onClose, onSubmit],
  )

  const formApiRef = useRef<UseFormReturn<TFieldValues>>()

  const handleCancel = useCallback<
    Required<FormProps<TValidationSchema, TVisibilitySchema, CMap, TContext>>['onCancel']
  >(async () => {
    onClose?.({}, 'escapeKeyDown')

    await onCancel?.(formApiRef.current?.getValues() ?? ({} as TFieldValues))
  }, [onCancel, onClose])

  return (
    <Form
      {...rootProps}
      formApiRef={formApiRef}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      formWrapper={ModalFormRoot}
      formWrapperProps={{
        maxWidth: 'xs',
        fullWidth: true,
        ...DialogProps,
        open: Boolean(open),
        title,
        loading,
        onClose: handleClose,
        className: classnames(slotClasses.root, className),
      }}
    >
      <ModalFormContent className={slotClasses.content}>{children}</ModalFormContent>
      <DialogActions className={slotClasses.actions}>{actions}</DialogActions>
    </Form>
  )
}

const ModalForm = React.memo(_ModalForm) as typeof _ModalForm

export default ModalForm
