import React from 'react'
import { useController, Path, FieldValues } from 'react-hook-form'

import type { AnyObjectSchema } from 'yup'

import { NumberField, NumberFieldProps, NumberFieldValue } from '@acre/design-system'
import { FieldBaseProps, useFieldValidationSchema, useFormApi, useFormContext } from '@acre/forms'

import getCommonTranslatedProps from '../../util/getCommonTranslatedProps'

export interface NumberFieldRHFProps<T extends FieldValues = FieldValues, ValueAsNumber extends boolean = true>
  extends FieldBaseProps,
    NumberFieldProps<ValueAsNumber> {
  name: Path<T>
}

function _NumberFieldRHF<
  T extends FieldValues = FieldValues,
  S extends AnyObjectSchema = AnyObjectSchema,
  ValueAsNumber extends boolean = true,
>(props: NumberFieldRHFProps<T, ValueAsNumber>) {
  const { name, shouldUnregister = true, ...other } = props

  const { control } = useFormContext<T>()

  const {
    field,
    fieldState: { error: fieldError },
  } = useController<T>({ name, control, shouldUnregister })

  const fieldSchema = useFieldValidationSchema<T, S>(name)

  const { parseText } = useFormApi()

  const { label, error, helperText } = getCommonTranslatedProps(props, { error: fieldError, parseText })

  return (
    <NumberField<ValueAsNumber>
      {...other}
      onChange={field.onChange}
      value={field.value ?? ((props.valueAsNumber ? 0 : '') as NumberFieldValue<ValueAsNumber>)}
      onBlur={field.onBlur}
      name={field.name}
      inputRef={field.ref}
      required={!fieldSchema?.spec?.optional}
      label={label}
      helperText={helperText}
      error={error}
      ErrorTextProps={{ slot: 'div' }}
    />
  )
}

const NumberFieldRHF = React.memo(_NumberFieldRHF) as typeof _NumberFieldRHF

export default NumberFieldRHF
