import React, { RefCallback, useCallback, useRef } from 'react'
import { inputBaseClasses, Stack, StackProps, styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'

import type { ExtendField, FieldProps } from '../Field/Field.types'
import useAriaProps from '../Field/hooks/useAriaProps'
import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import FieldWrapper, { fieldWrapperClasses } from '../FieldWrapper'
import NumberField, { NumberChangeEvent, NumberFieldProps } from '../NumberField'
import { getChangeValue, getInitialValues } from './YearMonthField.helpers'
import { getYearMonthFieldUtilityClass } from './yearMonthFieldClasses'

export interface YearMonthFieldProps extends FieldProps, ExtendField<StackProps> {
  hideMonths?: boolean
  hideYears?: boolean
  yearsLabel?: string
  monthsLabel?: string
  value?: number | null
  inputRef?: RefCallback<HTMLInputElement>
  onChange?: React.EventHandler<NumberChangeEvent>
  MonthsFieldProps?: Omit<NumberFieldProps, 'name' | 'defaultValue' | 'endAdornment' | 'size' | 'onChange'>
  YearsFieldProps?: Omit<NumberFieldProps, 'name' | 'defaultValue' | 'endAdornment' | 'size' | 'onChange'>
}

const YearMonthFieldRoot = styled(FieldWrapper, {
  name: 'YearMonthField',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})``

const YearMonthFieldFields = styled(Stack, {
  name: 'YearMonthField',
  slot: 'Fields',
  overridesResolver: (props, styles) => styles.fields,
})(({ theme }) => ({
  gap: 8,

  [`& .${fieldWrapperClasses.inputContainer}`]: {
    flexGrow: 1,
  },

  [`& .${inputBaseClasses.adornedEnd}`]: {
    backgroundColor: theme.palette.grey[100],
  },
}))

const YearMonthFieldMonths = styled(NumberField, {
  name: 'YearMonthField',
  slot: 'Months',
  overridesResolver: (props, styles) => styles.months,
})``

const YearMonthFieldYears = styled(NumberField, {
  name: 'YearMonthField',
  slot: 'Years',
  overridesResolver: (props, styles) => styles.years,
})``

const useUtilityClasses = (ownerState: Partial<YearMonthFieldProps>) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    fields: ['fields'],
    label: ['label'],
    months: ['months'],
    years: ['years'],
    helperText: ['helperText'],
    errorText: ['errorText'],
  }

  return unstable_composeClasses(slots, getYearMonthFieldUtilityClass, ownerState.classes)
}

const _YearMonthField = (props: YearMonthFieldProps) => {
  const {
    className,
    classes,
    yearsLabel = 'Years',
    monthsLabel = 'Months',
    hideMonths,
    hideYears,
    layout = 'row',
    size = 'medium',
    value,
    inputRef,
    onChange,
    MonthsFieldProps,
    YearsFieldProps,
    WrapperProps,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes, size, layout })
  const { fieldAriaProps } = useAriaProps(props)
  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, props)

  const initialValues = useRef<{ years: number | string | null; months: number | string | null } | undefined>()

  if (typeof value !== 'undefined' && !hideMonths && !initialValues.current) {
    const [years, months] = value ? getInitialValues(value) : ['', '']

    initialValues.current = { years, months }
  }

  const monthsRef = useRef(initialValues.current?.months)
  const yearsRef = useRef(initialValues.current?.years)

  let monthsValue: string | number | undefined
  let yearsValue: string | number | undefined

  if (value !== undefined) {
    monthsValue = monthsRef.current ?? ''
    yearsValue = yearsRef.current ?? ''
  }

  const handleChange = useCallback<Required<NumberFieldProps>['onChange']>(
    (e) => {
      const isNaN = Number.isNaN(e.target.valueAsNumber)
      const isYears = e.target.name.endsWith('.years')

      const value = e.target.value

      const event = { ...e, target: { ...e.target } }

      if (isYears) {
        yearsRef.current = isNaN ? '' : value
      } else {
        monthsRef.current = isNaN ? '' : value
      }

      if (isNaN) {
        event.target.value = 0
        event.target.valueAsNumber = 0
      }

      const changeValue = getChangeValue({
        values: {
          years: isYears && isNaN ? 0 : (yearsRef.current as number),
          months: !isYears && isNaN ? 0 : (monthsRef.current as number),
        },
        hideMonths,
      })

      event.target.value = changeValue
      event.target.valueAsNumber = changeValue

      onChange?.(event)
    },
    [hideMonths, onChange],
  )

  return (
    <YearMonthFieldRoot
      {...WrapperProps}
      disabled={rootProps.disabled}
      label={rootProps.label}
      error={rootProps.error}
      helperText={rootProps.helperText}
      name={rootProps.name}
      layout={layout}
      size={size}
      className={classnames(slotClasses.root, className)}
      InputLabelProps={labelProps}
      FormHelperTextProps={helperTextProps}
      ErrorTextProps={errorProps}
    >
      <YearMonthFieldFields
        {...fieldAriaProps}
        ref={inputRef}
        aria-label={rootProps.label?.toString()}
        role="group"
        direction="row"
        className={classnames(slotClasses.fields)}
      >
        {!hideYears ? (
          <YearMonthFieldYears
            {...YearsFieldProps}
            name={`${rootProps.name}.years`}
            value={yearsValue}
            endAdornment={yearsLabel}
            size={size}
            onChange={handleChange}
            error={Boolean(rootProps.error)}
            disabled={rootProps.disabled}
            inputProps={{ ...fieldAriaProps, 'aria-labelledby': '', 'aria-label': `${props.label}: ${yearsLabel}` }}
            WrapperProps={{ className: classnames(slotClasses.years, YearsFieldProps?.WrapperProps?.className) }}
          />
        ) : null}
        {!hideMonths ? (
          <YearMonthFieldMonths
            {...MonthsFieldProps}
            name={`${rootProps.name}.months`}
            value={monthsValue}
            endAdornment={monthsLabel}
            size={size}
            onChange={handleChange}
            error={Boolean(rootProps.error)}
            disabled={rootProps.disabled}
            inputProps={{ ...fieldAriaProps, 'aria-labelledby': '', 'aria-label': `${props.label}: ${monthsLabel}` }}
            WrapperProps={{ className: classnames(slotClasses.months, MonthsFieldProps?.WrapperProps?.className) }}
          />
        ) : null}
      </YearMonthFieldFields>
    </YearMonthFieldRoot>
  )
}

const YearMonthField = React.memo(_YearMonthField) as typeof _YearMonthField

export default YearMonthField
