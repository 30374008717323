import to from 'await-to-js'
import { GraphQLError } from 'graphql'

import { getCaseSurvey, updateCaseCpSurvey } from '../api_client_portal/leadgen'
import { Resolvers } from '../generated/resolvers'

const SurveyResolver: Resolvers = {
  Query: {
    survey: async (_, { id }: { id: string }) => {
      const [error, surveys] = await to(getCaseSurvey(id))

      if (error || !surveys.questions) {
        throw new GraphQLError('Unable to find surveys', { extensions: { status: 404 } })
      }

      return surveys
    },
  },

  Mutation: {
    updateCaseCpSurvey: (_parent, { input, id }) => {
      return updateCaseCpSurvey([input], id)
    },
  },
}

export default SurveyResolver
