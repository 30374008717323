import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface AutocompleteFieldClasses {
  root: string
  field: string
  label: string
  select: string
  helperText: string
  errorText: string
  list: string
  option: string
  placeholder: string
  searchIcon: string
  endAdornment: string
  clearSearchButton: string
}

export type AutocompleteFieldClassKey = keyof AutocompleteFieldClasses

export function getAutocompleteFieldUtilityClass(slot: string) {
  return generateUtilityClass('AutocompleteField', slot)
}

const autocompleteFieldClasses: AutocompleteFieldClasses = generateUtilityClasses('AutocompleteField', [
  'root',
  'field',
  'label',
  'select',
  'helperText',
  'errorText',
  'list',
  'option',
  'placeholder',
  'searchIcon',
  'endAdornment',
  'clearSearchButton',
])

export default autocompleteFieldClasses
