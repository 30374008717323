import { AnswerInput, SurveyAnswer } from '../generated/resolvers'
import oathKeeper from '../requesters/oathKeeper'
import oathKeeperPub from '../requesters/oathKeeperPub'
import { CdmSurveyAnswer } from '../service/luther'
import { CaseLoaderClient } from './case'

export const getCaseSurvey = async (case_id: string) => {
  const token = sessionStorage.getItem('token')

  const leadGenUrl =
    token === 'null' || token === 'undefined' || token === null
      ? `/lead-generation/case/${case_id}/survey`
      : `/lead-generation/case/${case_id}/survey?token=${token}`

  const requester = token === 'null' || token === null ? oathKeeper : oathKeeperPub

  try {
    const response = await requester.get(`${leadGenUrl}`, { withCredentials: true })
    return response.data
  } catch (error) {
    console.error('Error fetching case survey:', error)

    return null
  }
}

export const updateCaseCpSurvey = async (input: AnswerInput[], case_id: string) => {
  const token = sessionStorage.getItem('token')

  const leadGenUrl =
    token === 'null' || token === 'undefined' || token === null
      ? `/lead-generation/case/${case_id}/survey`
      : `/lead-generation/case/${case_id}/survey?token=${token}`

  const requester = token === 'null' || token === 'undefined' || token === null ? oathKeeper : oathKeeperPub

  const response = await requester.post<CdmSurveyAnswer>(`${leadGenUrl}`, {
    answers: input,
  })

  CaseLoaderClient.clear(case_id)

  return response.data.answers as SurveyAnswer
}
