import React from 'react'
import type { ArrayPath, FieldValues, Path } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'

import type { FormComponentSchema } from '../../types/schema'
import type {
  ComponentMapBase,
  ComponentSchemaPropsMapBase,
  FormCustomContext,
  InferPropsMap,
} from '../../types/schema-base'
import type { FieldTypes } from '../../types/types'
import Field, { FieldProps } from '../Field'
import useFormSchema from '../../hooks/useFormSchema'
import FieldWithData from '../../features/dataProvider/FieldWithData'
import getFieldSchemaByName from '../../util/getFieldSchemaByName'

export type SchemaFormComponentProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues> | ArrayPath<TFieldValues>,
  TComponentMap extends ComponentMapBase = ComponentMapBase,
  TContext extends FormCustomContext = FormCustomContext,
  TComponentPropsMap extends ComponentSchemaPropsMapBase = InferPropsMap<TComponentMap>,
> = FormComponentSchema<TFieldValues, TComponentPropsMap, TContext, TName>

const _SchemaFormComponent = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> | ArrayPath<TFieldValues> = Path<TFieldValues>,
  TComponentMap extends ComponentMapBase = ComponentMapBase,
  TContext extends FormCustomContext = FormCustomContext,
>(
  props: SchemaFormComponentProps<TFieldValues, TName, TComponentMap, TContext>,
) => {
  const { blockIndex, schema } = useFormSchema<TContext, TFieldValues, TComponentMap>()

  const fieldSchema =
    blockIndex && getFieldSchemaByName<TFieldValues, InferPropsMap<TComponentMap>, TContext>(blockIndex, props.name)

  if (!fieldSchema) {
    console.error(`Schema not found for ${props.name}`)
    return null
  }

  if (fieldSchema.data?.key) {
    const adapter = schema?.dataProviders?.find((provider) => provider.key === fieldSchema.data?.key)

    if (adapter) {
      return (
        <FieldWithData<TContext, TFieldValues>
          adapter={adapter}
          dataSchema={fieldSchema.data}
          fieldProps={props as FieldProps<FieldTypes, never, TFieldValues>}
        />
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Field<FieldTypes, any, AnyObjectSchema, TFieldValues> {...props} />
}

const SchemaFormComponent = React.memo(_SchemaFormComponent) as typeof _SchemaFormComponent

export default SchemaFormComponent
