import isNil from 'lodash/isNil'

import {
  CreateGeneralInsuranceProduct,
  GeneralInsurancePremiumFrequency,
  GeneralInsuranceQuote,
  GeneralInsuranceType,
  GeneralInsuranceYearsNoClaims,
  Maybe,
} from '@acre/graphql'
import { DropdownOption } from '@acre/design-system'

import { isSourcedGIProduct } from '../../GeneralInsurance.helpers'
import {
  FilterState,
  getSpecifiedItemList,
  InsuranceType,
  SpecificItem,
} from '../../GeneralInsuranceQuickQuote/Filters/FilterBar.helpers'

export type Props = {
  id: string
  preRecommendationProducts: CreateGeneralInsuranceProduct[]
  postRecommendationProducts: CreateGeneralInsuranceProduct[]
  commissionOptions: DropdownOption[]
  quotes?: GeneralInsuranceQuote[]
  recommendedProduct: Maybe<GeneralInsuranceQuote>
  loading?: boolean
  isApplicationComplete?: boolean
  error?: JSX.Element
  filterState?: FilterState
  setFilterState: (state: FilterState) => void
  desiredCommission?: number
  setDesiredCommission: (desiredCommission: number) => void
  hasPermissionToSourceGIProduct: boolean
}

export type GeneralInsuranceQuoteWithGIProductId = GeneralInsuranceQuote & {
  general_insurance_product_id?: Maybe<string>
}

// As we want to dispay manual product and sourced product in the same table
// we need to format manual product in the shape of sourced product (quote)
export const formatGIProductAsQuote = (
  giProduct: CreateGeneralInsuranceProduct,
): GeneralInsuranceQuoteWithGIProductId => {
  const { quote_reference, underwriter, premium_amount, premium_frequency, annual_premium, provider } =
    giProduct.details || {}

  const productPriceMonthly =
    premium_frequency === GeneralInsurancePremiumFrequency.PremiumFrequencyMonthly ? premium_amount : null

  return {
    general_insurance_product_id: giProduct.general_insurance_product_id,
    quote_id: quote_reference,
    external_system: provider,
    details: {
      insurer_name: underwriter,
      product_price_monthly: productPriceMonthly,
      product_price_annual: annual_premium ? annual_premium.toString() : '',
    },
  }
}

export const getQuotesToProcess = (
  preRecommendationProducts: CreateGeneralInsuranceProduct[],
  postRecommendationProducts: CreateGeneralInsuranceProduct[],
  quotes: Maybe<GeneralInsuranceQuote[]>,
  postRecommendationQuote: Maybe<GeneralInsuranceQuote>,
  addingManualGIEnabled: boolean,
) => {
  // In order to show GI product and quote together, we have to format GI product into quote's shape
  const preRecommendationQuotes = preRecommendationProducts
    .map((giProduct) => formatGIProductAsQuote(giProduct))
    .concat(quotes || [])

  // Similar here, we also need to format GI product into quote's shape
  // We also want to filter out sourced product, as postRecommendationQuote is post recommendation sourced product's quote
  const postRecommendationQuotes = postRecommendationProducts
    .filter((giProduct) => !isSourcedGIProduct(giProduct))
    .map((giProduct) => formatGIProductAsQuote(giProduct))
    .concat(postRecommendationQuote ? [postRecommendationQuote] : [])

  const hasPostRecommendationProducts = postRecommendationProducts.length > 0
  const quotesToProcess = hasPostRecommendationProducts ? postRecommendationQuotes : preRecommendationQuotes

  // when adding_manual_gi flag is off, only show sourced quotes
  const filteredQuotes = addingManualGIEnabled
    ? quotesToProcess
    : quotesToProcess.filter((quote) => quote.external_system !== 'MANUAL')

  // sort quotes by price
  return filteredQuotes.sort((a, b) => {
    const productPriceAnnualA = Number(a.details?.product_price_annual)
    const productPriceAnnualB = Number(b.details?.product_price_annual)
    const productPriceMonthlyA = Number(a.details?.product_price_monthly)
    const productPriceMonthlyB = Number(b.details?.product_price_monthly)

    if (productPriceAnnualA === productPriceAnnualB) {
      return productPriceMonthlyA - productPriceMonthlyB
    } else {
      return productPriceAnnualA - productPriceAnnualB
    }
  })
}

export const getProviderName = (name: string) => {
  switch (name) {
    case 'UINSURE':
      return 'Uinsure'
    case 'PAYMENTSHIELD':
      return 'PaymentShield'
    case 'HOMEINONE':
      return 'Home In One'
    default:
      return 'Unknown provider'
  }
}

export const getProviderLink = (name: string) => {
  switch (name) {
    case 'UINSURE':
      return 'https://uinsure.co.uk/home-insurance/'
    case 'PAYMENTSHIELD':
      return 'https://www.paymentshield.co.uk/your-policy-documents/home-insurance'
    default:
      return undefined
  }
}

// commission rate is represented as integer, i.e. 27.5% will be represented as 275
export const getGIProductCommission = (annualPremium: Maybe<number>, commissionRate: Maybe<number>) => {
  if (!annualPremium || !commissionRate) return null
  return Math.floor((annualPremium * commissionRate) / 1000)
}

export const getGIProductTypeFromFilter = (insuranceType: InsuranceType | undefined, btl: boolean) => {
  if (isNil(insuranceType)) return undefined

  if (insuranceType === InsuranceType.BuildingsOnly) {
    return GeneralInsuranceType.Buildings
  } else if (insuranceType === InsuranceType.ContentsOnly && btl) {
    return GeneralInsuranceType.LandlordsContents
  } else if (insuranceType === InsuranceType.ContentsOnly && !btl) {
    return GeneralInsuranceType.ContentsOnly
  } else if (insuranceType === InsuranceType.BuildingsAndContents && btl) {
    return GeneralInsuranceType.BuildingsAndLandlordsContents
  } else if (insuranceType === InsuranceType.BuildingsAndContents && !btl) {
    return GeneralInsuranceType.BuildingsAndContents
  }
}

export const YEARS_NO_CLAIMS_MAP: { [claimFreeYears: number]: GeneralInsuranceYearsNoClaims } = {
  0: GeneralInsuranceYearsNoClaims.Zero,
  1: GeneralInsuranceYearsNoClaims.One,
  2: GeneralInsuranceYearsNoClaims.Two,
  3: GeneralInsuranceYearsNoClaims.Three,
  4: GeneralInsuranceYearsNoClaims.Four,
  5: GeneralInsuranceYearsNoClaims.Five,
  6: GeneralInsuranceYearsNoClaims.Six,
  7: GeneralInsuranceYearsNoClaims.Seven,
  8: GeneralInsuranceYearsNoClaims.Eight,
  9: GeneralInsuranceYearsNoClaims.Nine,
  10: GeneralInsuranceYearsNoClaims.Ten,
}

export const getYearsNoClaimsFromFilter = (claimFreeYears: number | undefined) => {
  if (isNil(claimFreeYears) || claimFreeYears < 0) return
  if (claimFreeYears > 10) return GeneralInsuranceYearsNoClaims.Longer

  return YEARS_NO_CLAIMS_MAP[claimFreeYears]
}

export const formatSpecifiedItemsFromFilter = (specificItems: SpecificItem[] | undefined) => {
  if (!specificItems) return []

  const itemList = getSpecifiedItemList()

  return specificItems.map((specificItem) => {
    const { ItemType, ItemDescription, ItemValue, CoveredAtHome, CoveredOutsideTheHome } = specificItem
    return {
      type: itemList[ItemType].value,
      description: ItemDescription,
      value: ItemValue * 100,
      include_at_home: CoveredAtHome,
      include_away_from_home: CoveredOutsideTheHome,
    }
  })
}

// When a product is recommended in the GI sourcing page,
// we use this function to get details of quote and current state of filter bar to create a GI product
export const formatGIProductDetailsFromQuoteAndFilter = (
  quote: GeneralInsuranceQuote,
  filterState: FilterState | undefined,
  desiredCommission: number | undefined,
  btl: boolean,
) => {
  const { quote_id, external_system, policy_reference, details } = quote
  const { insurer_name, product_price_annual, product_price_monthly, cover_details } = details || {}
  const { contents_cover_level, buildings_cover_level, excess_contents, excess_buildings } = cover_details || {}

  const {
    insuranceType,
    manufactureOfDrugsCover,
    contentsPersonalPossessions,
    claimFreeYears,
    includeAccidentalCover,
    includeHomeEmergencyCover,
    includeFamilyLegalProtection,
    includePersonalPossessionsCover,
    contentsPersonalUnspecifiedValue,
  } = filterState || {}

  const annualPremium = product_price_annual ? parseInt(product_price_annual) : null

  const giProductType = getGIProductTypeFromFilter(insuranceType, btl)
  const yearsNoClaims = getYearsNoClaimsFromFilter(claimFreeYears)
  const giProductCommission = getGIProductCommission(annualPremium, desiredCommission)

  return {
    quote_reference: quote_id,
    type: giProductType,
    provider: external_system,
    policy_number: policy_reference,
    underwriter: insurer_name,
    premium_frequency: GeneralInsurancePremiumFrequency.PremiumFrequencyMonthly,
    premium_amount: product_price_monthly ?? null,
    annual_premium: annualPremium,
    contents_cover_level: contents_cover_level?.toString() || '0',
    buildings_cover_level: buildings_cover_level?.toString() || '0',
    excess: excess_contents,
    buildings_excess_if_different: excess_buildings,
    manufacture_of_drugs_cover: Boolean(manufactureOfDrugsCover),
    includes_accidental_damage: Boolean(includeAccidentalCover),
    includes_home_emergency_cover: Boolean(includeHomeEmergencyCover),
    includes_legal_cover: Boolean(includeFamilyLegalProtection),
    includes_personal_possessions_cover: Boolean(includePersonalPossessionsCover),
    personal_possessions_sum_assured: !isNil(contentsPersonalUnspecifiedValue)
      ? contentsPersonalUnspecifiedValue * 100
      : null,
    specified_items: formatSpecifiedItemsFromFilter(contentsPersonalPossessions),
    years_no_claims: yearsNoClaims || null,
    commission: giProductCommission,
  }
}
