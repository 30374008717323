import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Typography, useTheme } from '@mui/material'
import { Box } from '@mui/material'
import { camelCase, isEmpty, startCase } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import Select, {
  components,
  GroupProps,
  IndicatorProps,
  MultiValueProps,
  OptionProps,
  OptionTypeBase,
  Props,
  SingleValueProps,
  ValueType,
} from 'react-select'
import StateManager from 'react-select'
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { BUTTON_LINK, HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { DropdownOption } from '../../utils/types'
import Button from '../Button'
import Checkbox from '../Checkbox'
import Icon, { IconName } from '../FeatherIcon'
import { H3 } from '../Header'
import HelperText from '../HelperText'
import Label from '../Label'
import { groupifyOptions, OptionGroup } from './SearchSelector.helpers'
import { LabelAndInputWrapper } from '../../styles/form-control.styles'
import { Circle, selectStyles } from './SearchSelector.styles'

export type GroupVal = { value: string; group: string }

export type SearchSelectorProps<OptionType extends OptionTypeBase = DropdownOption, IsMulti extends boolean = true> = {
  id: string
  data?: OptionType[]
  label?: string
  value?: string[] | null
  onChange?: (value: IsMulti extends true ? string[] : string) => any
  onChangeWithGroup?: (arg: IsMulti extends true ? GroupVal[] : GroupVal) => void
  onBlur?: () => void
  error?: boolean
  disabled?: boolean
  message?: string
  placeholder?: string
  isMulti?: IsMulti
  useTagsOnMulti?: boolean
  isSearchable?: boolean
  openMenuOnClick?: boolean
  isMissing?: boolean
  isIncomplete?: boolean
  verificationError?: string
  variant?: Variant
  menuHeight?: string
  hideSelectedOptions?: boolean
  isLoading?: boolean
  selectGroup?: boolean
  showSelectAllOptions?: boolean
  displayMultiOptionsOnDisabled?: boolean
  heightCap?: boolean
  menuPortalTargetClass?: string
  showClear?: boolean
}

const SearchSelector = <OptionType extends OptionTypeBase = DropdownOption, IsMulti extends boolean = true>({
  id,
  data = [],
  label,
  value = [],
  onChange,
  error,
  disabled: disabledProp,
  message,
  onBlur,
  placeholder,
  isMulti: isMultiProp,
  isSearchable = true,
  openMenuOnClick = true,
  isMissing = false,
  isIncomplete = false,
  useTagsOnMulti = false,
  verificationError,
  variant,
  menuHeight,
  hideSelectedOptions = false,
  isLoading,
  selectGroup = false,
  onChangeWithGroup,
  showSelectAllOptions = true,
  displayMultiOptionsOnDisabled = false,
  heightCap = false,
  menuPortalTargetClass,
  showClear,
}: SearchSelectorProps<OptionType, IsMulti>) => {
  const intl = useIntl()
  const theme = useTheme()
  const formatMessage = (id: string, label?: string, less?: string) =>
    intl.formatMessage({ id }, { label, first: label, second: less })

  const selectRef = useRef<StateManager<OptionType, IsMulti>>(null)

  const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement>()

  useEffect(() => {
    if (menuPortalTargetClass) {
      setMenuPortalTarget(document.getElementsByClassName(menuPortalTargetClass)?.[0] as HTMLElement)
    }
  }, [menuPortalTargetClass])

  const disabled = useFieldDisabledState(disabledProp)

  const isMulti = isMultiProp !== undefined ? isMultiProp : (true as IsMulti)
  const multiDisabledShowOptions = disabled && isMulti && displayMultiOptionsOnDisabled

  const isGrouped = Boolean(data[0]?.options)

  // reformatting the data to be passed into the select component
  const dataOptions = isGrouped ? data : groupifyOptions(data as unknown as DropdownOption[])

  const values = (dataOptions as OptionGroup[]).reduce(
    (acc, option) => [...acc, ...option.options.filter((o) => value?.includes(o.value))],
    [] as DropdownOption[],
  ) as unknown as ValueType<OptionType, IsMulti>

  const isAllSelected = (props: any) => {
    return isEmpty(
      props.selectProps.options
        .find((o: OptionGroup) => o.label === props.children)
        .options.filter(
          (o: DropdownOption) => !props.selectProps.value.some((s: DropdownOption) => s.value === o.value),
        ),
    )
  }

  const showSelectALl = (props: any) => {
    return props.selectProps.options.find((o: OptionGroup) => o.label === props.children).options.length > 1
  }

  const isAllCleared = (props: any) => props.selectProps.value?.length === 0

  const handleClearAll = () => {
    if (selectRef.current && selectRef.current.select) {
      selectRef.current.select.clearValue()

      const emptyValue: ValueType<OptionType, IsMulti> =
        isMulti || (!isMulti && showClear) ? ([] as unknown as ValueType<OptionType, IsMulti>) : null

      selectRef.current.select.setValue(emptyValue, 'set-value')
    }
  }

  // on change the change handler passed in will be triggered after transforming the options to be an array of strings.
  const handleChange = useCallback<Required<Props<OptionType, IsMulti>>['onChange']>(
    (value) => {
      if (multiDisabledShowOptions) return

      if (selectGroup) {
        const optionsArr = ((Array.isArray(value) ? value : [value]) || []).map(
          (item) =>
            item &&
            ({
              value: item.value,
              group: item.group,
            } as GroupVal),
        )

        return onChangeWithGroup && onChangeWithGroup(isMulti ? optionsArr : optionsArr[0])
      }

      const optionsArr = value && (Array.isArray(value) ? value : [value]).map((item) => item && (item.value as string))

      optionsArr && onChange && onChange(isMulti ? optionsArr : optionsArr[0])
    },
    [isMulti, multiDisabledShowOptions, onChange, onChangeWithGroup, selectGroup],
  )

  // collect styles which have theme applied to it
  const styles = selectStyles(theme, variant, heightCap, error, menuHeight, multiDisabledShowOptions)

  // A component to replace MultiValue
  const MultiValue = (props: MultiValueProps<any>) => {
    const { menuIsOpen, inputValue, value } = props.selectProps

    let text = value?.length > 1 ? `${value?.length} selected ` : value[0]?.label

    if (menuIsOpen && isSearchable && inputValue) return <></>

    return useTagsOnMulti ? (
      <components.MultiValue {...props}>{props.children}</components.MultiValue>
    ) : (
      <components.SingleValue {...props}>{text}</components.SingleValue>
    )
  }

  const MultiValueRemove = (props: MultiValueRemoveProps<any>) => {
    const isLast = props.selectProps.value.length === 1

    if (isLast) {
      props.innerProps.onClick = () => handleClearAll()
    }

    return <components.MultiValueRemove {...props} />
  }

  const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
    <components.SingleValue {...props}>{children}</components.SingleValue>
  )

  // A component to replace Option
  const OptionMulti = (props: OptionProps<any, any>) => {
    const hasCount = Boolean(props.data.count)
    return (
      <div
        data-testid={testHandle(`${id}Option${startCase(camelCase(props.children?.toString())).replace(/ /g, '')}`)}
        aria-disabled={props.isDisabled}
      >
        <components.Option {...props}>
          <Box mr={1}>
            <Checkbox
              id={id}
              disabled={props.isDisabled || multiDisabledShowOptions}
              checked={props.isSelected}
              readOnly
            />
          </Box>
          {hasCount ? (
            <Box display="flex" justifyContent="space-between" flexGrow={1} whiteSpace="pre-wrap">
              <Typography>{`${props.children} `}</Typography>
              <Typography>({props.data.count})</Typography>
            </Box>
          ) : (
            props.children
          )}
        </components.Option>
      </div>
    )
  }

  const OptionSingle = (props: OptionProps<any, any>) => {
    return (
      <div
        data-testid={testHandle(`${id}Option${startCase(camelCase(props.children?.toString())).replace(/ /g, '')}`)}
        aria-disabled={props.isDisabled}
      >
        <components.Option {...props}>
          <Circle className={props.isSelected ? 'checked' : ''} />
          {props.children}
        </components.Option>
      </div>
    )
  }

  const Option = isMulti ? OptionMulti : OptionSingle

  const GroupHeading = (props: GroupProps<any, any>) => {
    const isFirstGroup =
      props.selectProps.options && props.selectProps.options[0] && props.children === props.selectProps.options[0].label
    const groupName = props.children

    const noHeadingForMultiSelector = Boolean(isMulti && (!showSelectAllOptions || multiDisabledShowOptions))
    const noHeadingForSingleSelector = Boolean(!isMulti && !showClear)

    if (noHeadingForMultiSelector || noHeadingForSingleSelector) return

    // Single search selector heading
    if (!isMulti && showClear) {
      return (
        <Box data-testid={testHandle(`${id}GroupHeadingFor${groupName}`)} px={2}>
          <Box my={1}>
            <Button disabled={isAllCleared(props)} id={`${id}Clear`} buttonStyle={BUTTON_LINK} onClick={handleClearAll}>
              <FormattedMessage id="searchSelector.clear" />
            </Button>
          </Box>
        </Box>
      )
    }

    // Multi search selector heading
    return (
      <Box data-testid={testHandle(`${id}GroupHeadingFor${groupName}`)} px={2}>
        <>
          {isGrouped && isFirstGroup && isMulti && (
            <Box my={1}>
              <Button
                disabled={isAllCleared(props)}
                id={`${id}ClearAll`}
                buttonStyle={BUTTON_LINK}
                onClick={handleClearAll}
              >
                <FormattedMessage id="searchSelector.clearAll" />
              </Button>
            </Box>
          )}

          {isGrouped ? (
            <Box width="100%">
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box my={1}>
                  <H3 styledAs="h5">{props.children}</H3>
                </Box>
                {showSelectALl(props) && isMulti && (
                  <Box mr={2} my={1}>
                    <Button
                      disabled={isAllSelected(props)}
                      id={`${id}SelectAll`}
                      buttonStyle={BUTTON_LINK}
                      onClick={() =>
                        selectRef.current &&
                        props.selectProps.options &&
                        selectRef.current.select.setValue(
                          props.selectProps.options.find((o) => o.label === props.children).options,
                          'set-value',
                        )
                      }
                    >
                      {`${formatMessage('searchSelector.selectAll')}  ${props.children?.toString().toLowerCase()}`}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box display="flex" justifyContent="flex-start" width="100%">
              <Box mr={2} my={1}>
                <Button
                  disabled={isAllSelected(props)}
                  id={`${id}SelectAll`}
                  buttonStyle={BUTTON_LINK}
                  onClick={() =>
                    selectRef.current &&
                    props?.selectProps?.options?.[0]?.options &&
                    selectRef.current.select.setValue(props.selectProps.options[0].options, 'set-value')
                  }
                >
                  <FormattedMessage id="searchSelector.selectAll" />
                </Button>
              </Box>

              <Box my={1}>
                <Button
                  disabled={isAllCleared(props)}
                  id={`${id}ClearAll`}
                  buttonStyle={BUTTON_LINK}
                  onClick={handleClearAll}
                >
                  <FormattedMessage id="searchSelector.clearAll" />
                </Button>
              </Box>
            </Box>
          )}
        </>
      </Box>
    )
  }

  // a component to replace the dropdown icon with a custom caret in the select component
  const DropdownIndicator = (props: IndicatorProps<any, any>) => (
    <components.DropdownIndicator {...props}>
      <Icon name={isSearchable ? IconName.Search : IconName.ChevronDown} />
    </components.DropdownIndicator>
  )

  return (
    <div data-testid={testHandle(id)}>
      <LabelAndInputWrapper variant={variant}>
        {label && (
          <Label
            htmlFor={id}
            isDisabled={false}
            text={label}
            isMissing={isMissing}
            isIncomplete={isIncomplete}
            verificationError={verificationError}
            variant={variant}
            customMarginBottom={theme.spacers.size4}
          />
        )}
        <Select<OptionType, IsMulti>
          id={id}
          aria-label={label ? label : id}
          ref={selectRef}
          styles={styles}
          isMulti={isMulti}
          isLoading={isLoading}
          isSearchable={isSearchable}
          isClearable={false}
          closeMenuOnSelect={!isMulti && !showClear}
          openMenuOnClick={openMenuOnClick}
          tabSelectsValue={false}
          isDisabled={disabled && !multiDisabledShowOptions}
          options={dataOptions as OptionType[]}
          menuPortalTarget={menuPortalTarget}
          onChange={handleChange}
          onBlur={onBlur}
          value={values as ValueType<OptionType, IsMulti>}
          placeholder={placeholder}
          hideSelectedOptions={hideSelectedOptions}
          components={{
            SingleValue,
            MultiValue,
            Option,
            DropdownIndicator,
            GroupHeading,
            MultiValueRemove,
          }}
        />
      </LabelAndInputWrapper>

      {message && (
        <HelperText id={id} message={message || ''} textType={error ? HELPER_TYPE_ERROR : ''} variant={variant} />
      )}

      {isMissing && (
        <HelperText
          id={`${id}Helper`}
          message={formatMessage('errors.missingFieldRequired')}
          textType={HELPER_TYPE_ERROR}
          variant={variant}
        />
      )}
    </div>
  )
}

export default withDisabled(SearchSelector) as typeof SearchSelector
