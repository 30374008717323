import React from 'react'
import type { ArrayPath, FieldValues } from 'react-hook-form'

import type { FormComponentSchema } from '../../types/schema'
import SchemaFormComponent from './SchemaFormComponent'
import SchemaFieldArrayComponent, { SchemaFieldArrayComponentProps } from './SchemaFieldArrayComponent'
import { ComponentSchemaPropsMapBase, FormCustomContext } from '../../types/schema-base'

export interface SchemaFormComponentsProps<TFieldValues extends FieldValues, TContext extends FormCustomContext> {
  components: FormComponentSchema<TFieldValues, ComponentSchemaPropsMapBase, TContext>[]
}

const _SchemaFormComponents = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends FormCustomContext = FormCustomContext,
>({
  components,
}: SchemaFormComponentsProps<TFieldValues, TContext>) => {
  return (
    <>
      {components.map((component, idx) => {
        if ('_components' in component && component._components) {
          return <SchemaFormComponents<TFieldValues, TContext> key={idx} components={component._components} />
        }

        if (component.type === 'array') {
          return (
            <SchemaFieldArrayComponent
              key={idx}
              // Explicitly cast here because I'm pretty sure we can never resolve this type here but it doesn't matter
              {...(component as unknown as SchemaFieldArrayComponentProps<TFieldValues, ArrayPath<TFieldValues>>)}
              name={component.name as ArrayPath<TFieldValues>}
            />
          )
        }

        return <SchemaFormComponent key={idx} {...component} />
      })}
    </>
  )
}

const SchemaFormComponents = React.memo(_SchemaFormComponents) as typeof _SchemaFormComponents

export default SchemaFormComponents
