import AccountingResolver from './Accounting'
import AddressLookupResolver from './AddressLookup'
import AuthProviderResolver from './AuthProvider'
import AuthURLResolver from './AuthURLs'
import BrowserIDResolvers from './BrowserId'
import CaseResolver from './Case'
import CheckClientResolver from './CheckClient'
import ClientResolver from './Client/Client'
import CommissionStructureResolver from './CommissionStructure'
import CompaniesHouseResolver from './companiesHouse/CompaniesHouse'
import CreditReportResolvers from './CreditReport'
import DashboardsFilterResolver from './DashboardsFilter'
import DipResolver from './Dip'
import DocumentsResolver from './Document'
import DocumentRequirementResolvers from './DocumentRequirement'
import DocumentVerificationResolver from './DocumentVerification'
import FlagResolver from './Flag'
import GeneralInsuranceResolver from './generalInsurance/GeneralInsurance'
import GroupResolver from './Group'
import KbaResolver from './Kba'
import MortgageResolver from './Mortgage'
import NoteResolver from './Notes'
import NotificationsResolver from './Notifications'
import NylasResolver from './Nylas'
import OauthResolver from './Oauth'
import Onfido from './Onfido'
import OrgAndIntroducerAndAdvisorFilterResolver from './OrgAndIntroducerAndAdvisorFilter'
import OrganisationResolver from './Organisation'
import OrgRegulatingFilterResolver from './OrgRegulatingFilter'
import PermissionResolver from './Permission'
import ProductResolver from './Product'
import PropertyResolver from './Property'
import ProtectionProduct from './protection/ProtectionProduct'
import SolutionResolver from './protection/Solution'
import ReportsResolver from './Reports'
import RoleResolver from './Role'
import SchedulerResolver from './Scheduler'
import SearchResolver from './Search'
import SuitabilityResolvers from './Suitability'
import SurveyResolver from './Survey'
import TagResolver from './Tag'
import TemplateResolver from './Template'
import UserResolver from './User'
import ValuationResolver from './Valuation/valuation'
import ZendeskResolver from './Zendesk'

type ResolverObj = {
  [key: string]: any
}

const mergeResolvers = (...resolvers: Array<ResolverObj>) => {
  const newObj: ResolverObj = {}

  resolvers.forEach((obj) => {
    obj &&
      Object.keys(obj).forEach((key) => {
        if (!newObj[key]) newObj[key] = obj[key]
        newObj[key] = { ...newObj[key], ...obj[key] }
      })
  })

  return newObj
}

const Resolvers = mergeResolvers(
  AddressLookupResolver,
  AuthProviderResolver,
  AuthURLResolver,
  BrowserIDResolvers,
  CaseResolver,
  CheckClientResolver,
  ClientResolver,
  CompaniesHouseResolver,
  CommissionStructureResolver,
  DashboardsFilterResolver,
  DocumentVerificationResolver,
  DocumentRequirementResolvers,
  DocumentsResolver,
  GeneralInsuranceResolver,
  MortgageResolver,
  OauthResolver,
  Onfido,
  OrganisationResolver,
  PermissionResolver,
  ProductResolver,
  PropertyResolver,
  ProtectionProduct,
  SchedulerResolver,
  SearchResolver,
  SolutionResolver,
  SuitabilityResolvers,
  SurveyResolver,
  ReportsResolver,
  UserResolver,
  TagResolver,
  OrgAndIntroducerAndAdvisorFilterResolver,
  OrgRegulatingFilterResolver,
  TemplateResolver,
  NoteResolver,
  AccountingResolver,
  ValuationResolver,
  RoleResolver,
  GroupResolver,
  NylasResolver,
  DipResolver,
  NotificationsResolver,
  SchedulerResolver,
  KbaResolver,
  CreditReportResolvers,
  ZendeskResolver,
  FlagResolver,
)

export default Resolvers
