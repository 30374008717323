import {
  Case,
  CaseStatus,
  formatAsCurrency,
  Maybe,
  Mortgage,
  MortgageReason,
  MortgageStatus,
  SharedOwnershipType,
  UpdateCaseStatusRequestUpdateType,
} from '@acre/graphql'

const getSchemeMoney = (details: Case, propertyValue: Maybe<number>) => {
  const sharedOwnershipType = details.new_property?.details.shared_ownership_type
  const htbLoanAmount = details.new_property?.details.help_to_buy_loan_amount
    ? parseInt(details.new_property?.details.help_to_buy_loan_amount)
    : 0

  if (!propertyValue) {
    return 0
  }

  if (sharedOwnershipType === SharedOwnershipType.ShareDownership) {
    return propertyValue * (1 - (details.new_property?.details.shared_ownership_share || 0) / 10000000)
  }

  if (
    sharedOwnershipType === SharedOwnershipType.HelpToBuy ||
    sharedOwnershipType === SharedOwnershipType.PrivateSharedEquity
  ) {
    return htbLoanAmount
  }

  return 0
}

const getRevisedEquity = (details: Case, depositUnformatted: number, propertyValue: Maybe<number>) => {
  const preferenceLoanAmount = details.preference_loan_amount ? parseInt(details.preference_loan_amount) : 0
  const additionalBorrowing = details.calculated_values?.total_additional_borrowing
    ? parseInt(details.calculated_values?.total_additional_borrowing)
    : 0

  const schemeMoney = getSchemeMoney(details, propertyValue)

  if (!propertyValue) {
    return null
  }

  return propertyValue - schemeMoney + depositUnformatted - preferenceLoanAmount - additionalBorrowing
}

export const getDepositOrEquity = (
  details: Case,
  mortgageReason: MortgageReason,
  defaultValue: JSX.Element | string,
) => {
  const propertyValuation = details.new_property?.details.property_valuation
  const preferenceTargetPropertyValue = details.preference_target_property_value ?? ''
  const propertyValue = propertyValuation ? parseInt(propertyValuation) : parseInt(preferenceTargetPropertyValue)

  const existingDepositsSum = details.clients
    ?.flatMap((client) => client.details.deposits?.filter((deposit) => deposit.deposit_for?.includes(details.id)) ?? [])
    .reduce((sum, deposit) => sum + parseInt(deposit?.amount || ''), 0)

  const preferenceTargetDepositValue = details.preference_target_deposit ?? ''
  const depositUnformatted = existingDepositsSum ? existingDepositsSum : parseInt(preferenceTargetDepositValue)

  const revisedEquity = getRevisedEquity(details, depositUnformatted || 0, propertyValue)
  const equityUnformatted = revisedEquity
  const equityToDisplay = equityUnformatted ? formatAsCurrency(equityUnformatted) : defaultValue

  const deposit = depositUnformatted ? formatAsCurrency(depositUnformatted) : defaultValue

  switch (mortgageReason) {
    case MortgageReason.ReasonBtlRemortgage:
    case MortgageReason.ReasonRemortgage:
      return equityToDisplay
    case MortgageReason.ReasonFtb:
    case MortgageReason.ReasonBtl:
    case MortgageReason.ReasonHouseMove:
      return deposit
    default:
      return defaultValue
  }
}

export const filterOfferLetterMortgage = (caseMortgages: Mortgage[]): Mortgage | undefined =>
  caseMortgages.find(
    (mortgage) => mortgage.status === MortgageStatus.StatusSelected && Boolean(mortgage.application_date),
  )

export const filterSelectedMortgage = (caseMortgages: Mortgage[]): Mortgage | undefined =>
  caseMortgages.find((mortgage) => mortgage.status === MortgageStatus.StatusSelected)

export const addSpaceBetween = (text: string, displayName: string) => {
  const textClean = text.trim()
  const displayNameClean = displayName.trim()
  return displayNameClean.length ? `${textClean} ${displayNameClean}` : textClean
}

export const getRollbackStatus = (currentStatus?: Maybe<CaseStatus>) => {
  switch (currentStatus) {
    case CaseStatus.Exchange:
      return UpdateCaseStatusRequestUpdateType.Offered
    case CaseStatus.OfferReceived:
      return UpdateCaseStatusRequestUpdateType.AwaitingOffer
    case CaseStatus.AwaitingOffer:
      return UpdateCaseStatusRequestUpdateType.AwaitingValuation
    case CaseStatus.AwaitingValuation:
      return UpdateCaseStatusRequestUpdateType.ApplicationSubmitted
    case CaseStatus.ApplicationSubmitted:
      return UpdateCaseStatusRequestUpdateType.Recommendation
    case CaseStatus.PreApplication:
      return UpdateCaseStatusRequestUpdateType.ArchiveRecommendation
    case CaseStatus.PreRecommendation:
      return UpdateCaseStatusRequestUpdateType.Lead
    default:
      return null
  }
}

export const getRollbackStatusModalMessage = (
  status?: Maybe<UpdateCaseStatusRequestUpdateType>,
  mortgageReason?: Maybe<MortgageReason>,
) => {
  let name: string | undefined
  switch (status) {
    case UpdateCaseStatusRequestUpdateType.Offered:
      name = 'toOffered'
      break
    case UpdateCaseStatusRequestUpdateType.Exchange:
      name = 'toExchange'
      break
    case UpdateCaseStatusRequestUpdateType.AwaitingOffer:
      name = 'toAwaitingOffer'
      break
    case UpdateCaseStatusRequestUpdateType.AwaitingValuation:
      name = 'toAwaitingValuation'
      break
    case UpdateCaseStatusRequestUpdateType.ApplicationSubmitted:
      name = 'toApplicationSubmitted'
      break
    case UpdateCaseStatusRequestUpdateType.Recommendation:
      name = 'toPreApplication'
      break
    case UpdateCaseStatusRequestUpdateType.ArchiveRecommendation:
      name = mortgageReason === MortgageReason.ReasonGeneralInsurance ? 'toPreRecommendationGI' : 'toPreRecommendation'
      break
    case UpdateCaseStatusRequestUpdateType.Lead:
      name = 'toLead'
      break
    default:
      throw new Error(`Unsupported rollback status: ${status}`)
  }

  return `rollbackCaseTransitions.${name}.modalMessage`
}
