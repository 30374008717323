import { type DetailedHTMLProps, forwardRef, type HTMLAttributes, memo } from 'react'

import AutocompleteFieldMultiControls from './AutocompleteFieldMultiControls'

export interface AutocompleteFieldListBoxProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement> {
  multiControls?: boolean
  selectedCount?: number
  optionCount?: number
  onClearAll: () => void
  onSelectAll: () => void
}

const _AutocompleteFieldListBox = forwardRef<HTMLUListElement, AutocompleteFieldListBoxProps>(
  function _AutocompleteFieldListBox(props: AutocompleteFieldListBoxProps, ref) {
    const { children, multiControls, selectedCount, optionCount, onClearAll, onSelectAll, ...rest } = props

    return (
      <ul ref={ref} {...rest}>
        {multiControls && (
          <AutocompleteFieldMultiControls
            ownerId={rest.id}
            selectedCount={selectedCount || 0}
            optionCount={optionCount || 0}
            onSelectAll={onSelectAll}
            onClearAll={onClearAll}
          />
        )}
        {children}
      </ul>
    )
  },
)

const AutocompleteFieldListBox = memo(_AutocompleteFieldListBox) as typeof _AutocompleteFieldListBox
export default AutocompleteFieldListBox
