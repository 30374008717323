import {
  CommissionStructure,
  CommissionStructureResponse,
  GetIntroducersCommissionsListQueryVariables,
  IntroducerCommissions,
  MutationCreateCommissionStructureArgs,
  MutationUpdateCommissionStructureArgs,
  QueryGetCommissionStructuresArgs,
} from '../generated/resolvers'
import request from '../requesters/default'
import { addInternalHash, removeInternalHash } from '../utils/schemaMapping/client'

export const getCommissionStructure = async (args: QueryGetCommissionStructuresArgs) => {
  const response = await request.get<CommissionStructureResponse>('/commission_structure', {
    params: args.input,
  })

  const responseWithIds =
    response?.data?.commission_structures?.map((commission) => ({
      ...commission,
      commissions: addInternalHash(commission?.commissions) ?? [],
    })) || []

  return { ...response.data, commission_structures: responseWithIds }
}

export const getIntroducers = async (args: GetIntroducersCommissionsListQueryVariables) => {
  const response = await request.get<IntroducerCommissions>('/mi/firm/introducers', {
    params: args.input,
  })

  return response.data
}

export const postCommissionStructure = async (args: MutationCreateCommissionStructureArgs) => {
  const payload = {
    ...args.input,
    commissions: removeInternalHash(args.input.commissions),
  }
  const response = await request.post<{ commission_structure: CommissionStructure }>(`/commission_structure`, payload)
  const responseWithIds = {
    ...response.data,
    commission_structure: {
      ...response.data.commission_structure,
      commissions: addInternalHash(response.data.commission_structure.commissions),
    },
  }
  return responseWithIds.commission_structure
}

export const patchCommissionStructure = async (args: MutationUpdateCommissionStructureArgs) => {
  const payload = {
    ...args.input,
    commissions: removeInternalHash(args.input.commissions),
  }

  const response = await request.patch<{ commission_structure: CommissionStructure }>(
    `/commission_structure/${args.id}`,
    payload,
  )

  const responseWithIds = {
    ...response.data,
    commission_structure: {
      ...response.data.commission_structure,
      commissions: addInternalHash(response.data.commission_structure.commissions),
    },
  }

  return responseWithIds.commission_structure
}
