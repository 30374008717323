import React, { MouseEvent as ReactMouseEvent, ReactElement } from 'react'
import classNames from 'classnames'

import { testHandle } from '@acre/utils'
import { Maybe } from '@acre/graphql'

import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { ItemCardData } from '../../utils/types'
import { ColourCardTypes } from '../ColourCard/ColourCard.types'
import { H3 } from '../Header'
import { CenteredLoadingSpinner } from '../LoadingSpinner'
import CardContent from './CardContent'
import { hasButtonParent, setIsClickable } from './ColouredItemCard.helpers'
import { AddItemCard, CardWrapper, ClickableCardWrapper } from './ColouredItemCard.styles'

type Props = {
  title?: string
  options?: ReactElement
  data?: ItemCardData[]
  onClick?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void
  id?: string
  colourVariant?: ColourCardTypes
  checkbox?: Maybe<JSX.Element>
  disabled?: boolean
  loading?: boolean
}

const ColouredItemCard = ({ title, options, data, onClick, id, colourVariant, checkbox, disabled, loading }: Props) => {
  const isDisabled = useFieldDisabledState(disabled)

  const isAddItemCard = !data

  const isClickable = setIsClickable(isDisabled, onClick)

  // Handle click on the card, ensuring that we aren't clicking a button inside the card
  const handleClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Keep the event so we can pass it on
    evt.persist()

    const target = evt.target as HTMLElement

    if (target && !hasButtonParent(target) && onClick) {
      onClick(evt)
    }
  }

  const cardContent = (
    <CardContent
      title={title}
      options={options}
      data={data}
      checkbox={checkbox}
      colourVariant={colourVariant}
      id={id}
    />
  )

  if (isAddItemCard) {
    return (
      <AddItemCard
        className={`${classNames({ disabled: isDisabled })}`}
        colourVariant={colourVariant}
        onClick={(e) => isClickable && handleClick(e)}
        disabled={isDisabled}
        id={id}
        data-testid={id ? testHandle(id) : null}
      >
        {loading ? <CenteredLoadingSpinner /> : <H3>{title}</H3>}
      </AddItemCard>
    )
  }

  if (!isClickable) {
    return (
      <CardWrapper
        className={`${classNames({ disabled: isDisabled })}`}
        id={id}
        data-testid={id ? testHandle(id) : null}
        colourVariant={colourVariant}
        disabled={isDisabled}
      >
        {cardContent}
      </CardWrapper>
    )
  }

  return (
    <ClickableCardWrapper
      data-testid={id ? testHandle(id) : null}
      onClick={handleClick}
      id={id}
      colourVariant={colourVariant}
    >
      {cardContent}
    </ClickableCardWrapper>
  )
}

export default ColouredItemCard
