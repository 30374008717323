import { FieldValues } from "react-hook-form"
import { FormComponentSchema } from "../types/schema"
import { ComponentSchemaPropsMapBase, FormCustomContext } from "../types/schema-base"
import { get } from "lodash"

const getFieldSchemaByName = <TFieldValues extends FieldValues, TComponentMap extends Partial<ComponentSchemaPropsMapBase>, TContext extends FormCustomContext>(blockIndex: Record<string, FormComponentSchema<TFieldValues, TComponentMap, TContext>>, name: string) => {
  if (/\.[0-9+]\./.test(name)) {
    const fixedArrayPath = name.replace(/\.[0-9+](\.[a-zA-Z0-9_-]+)/g, '$1')
    return get(blockIndex, fixedArrayPath)
  }

  return get(blockIndex, name)
}

export default getFieldSchemaByName