import React from 'react'
import { Box, BoxProps, styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'

import Button from '../ButtonNewest/Button'
import { getFieldArrayItemUtilityClass } from './fieldArrayItemClasses'

export interface FieldArrayItemProps extends BoxProps {
  index: number
  hideRemoveButton?: boolean
  removeButtonText?: string
  onRemoveClick: () => void
}

const FieldArrayItemRoot = styled(Box, {
  name: 'FieldArrayItem',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => {
  const fieldsetPadding = 0
  const bgColor = undefined

  return {
    backgroundColor: bgColor,
    padding: fieldsetPadding,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: fieldsetPadding,
    },
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: fieldsetPadding ? 'calc(100% + 2rem)' : '100%',
  }
})

const useUtilityClasses = (ownerState: Partial<FieldArrayItemProps>) => {
  const slots = {
    root: ['root'],
  }

  return unstable_composeClasses(slots, getFieldArrayItemUtilityClass, ownerState.classes)
}

const DEFAULT_REMOVE_BUTTON_TEXT = '- remove item'

const _FieldArrayItem = (props: FieldArrayItemProps) => {
  const {
    className,
    classes,
    children,
    index,
    hideRemoveButton,
    removeButtonText = DEFAULT_REMOVE_BUTTON_TEXT,
    onRemoveClick,
    ...rootProps
  } = props

  const slotClasses = useUtilityClasses({ classes })

  return (
    <FieldArrayItemRoot {...rootProps} className={classnames(slotClasses.root, className)}>
      {children}

      {!hideRemoveButton && index !== 0 && (
        <Button size="small" onClick={onRemoveClick}>
          {removeButtonText}
        </Button>
      )}
    </FieldArrayItemRoot>
  )
}

const FieldArrayItem = React.memo(_FieldArrayItem) as typeof _FieldArrayItem

export default FieldArrayItem
