import DataLoader from 'dataloader'

import { CaseInput, CaseVersion } from '../generated/resolvers'
import request from '../requesters/clientPortal'
import oathKeeper from '../requesters/oathKeeper'
import { CdmGetCaseResponse, CdmUpdateCaseResponse } from '../service/luther'
import { UUID } from '../types'
import { formatCase } from '../utils/schemaMapping/case'

const fetchCasesBatchFn = async (ids: UUID[]) => {
  const caseIds = ids.join('&case_ids=')
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request
  const response = await requester.get<CdmGetCaseResponse>(`/case?case_ids=${caseIds}&case_details=true`)

  return response.data.cases?.map(formatCase) || []
}

export const CaseLoaderClient = new DataLoader(async (ids: string[]) => {
  const cases = await fetchCasesBatchFn(ids)
  const data = ids.map((id) => cases.find((caseData) => caseData.id === id) || null)

  return data
})

export const fetchCase = async (id: UUID) => {
  return await CaseLoaderClient.load(id)
}

export const fetchCasesForClient = async (clientId: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<CdmGetCaseResponse>(`/case?case_details=false&filter_client_ids=${clientId}`)

  const caseIds = response.data.cases?.reduce((acc, caseData) => {
    if (caseData.case_id) {
      acc.push(caseData.case_id)
    }

    return acc
  }, [] as string[])

  if (!caseIds) {
    return []
  }

  const cases = await CaseLoaderClient.loadMany(caseIds)

  return cases.filter((caseData) => caseData !== null) as CaseVersion[]
}

export const updateCaseCp = async (input: CaseInput, id: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')


  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.patch<CdmUpdateCaseResponse>(`/case/${id}`, {
    details: input,
  })

  CaseLoaderClient.clear(id)

  return response.data.case ? formatCase(response.data.case) : null
}
