import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface RadioFieldClasses {
  root: string
  radio: string
  icon: string
  checkedIcon: string
}

export type RadioFieldClassKey = keyof RadioFieldClasses

export function getRadioFieldUtilityClass(slot: string) {
  return generateUtilityClass('RadioField', slot)
}

const radioFieldClasses: RadioFieldClasses = generateUtilityClasses('RadioField', [
  'root',
  'radio',
  'icon',
  'checkedIcon',
])

export default radioFieldClasses
