import { styled, Theme } from '@mui/material'
import { Box } from '@mui/material'
import { rem } from 'polished'

import { Maybe } from '@acre/graphql'

import { Variant } from '../../utils/constants'

export const RadioGroupWrapper = styled('div')<{
  variant?: Variant
  labelWidth?: Maybe<string>
}>(
  ({ theme, variant, labelWidth }) => `
  /* Isolate Stacking context, so that z-indices set as part of the radio buttons, do not affect external layouts */
  isolation: isolate;
  display: flex;
  flex-direction: column;
  position: relative;

  ${variant == 'compact' && radioGroupWrapperCompactStyles(theme)};
  ${variant == 'compactRadioLeft' && radioGroupWrapperCompactRadioStyles(theme)};
  ${variant == 'compactLeftAligned' && radioGroupWrapperNewCompactStyles({ theme, labelWidth })};
`,
)

const radioGroupWrapperCompactStyles = (theme: Theme) => `
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & > div {
    width: 100%;
  }
  & > label {
    width: 33%;
    min-width: 33%;
    text-align: right;
    font-size: 12px;
    transform: translateX(-${theme.spacers.size8});
  }
`

const radioGroupWrapperCompactRadioStyles = (theme: Theme) => `
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & > div {
    width: 100%;
  }
  & > label {
    width: 33%;
    min-width: 33%;
    text-align: left;
    font-size: 12px;
    transform: translateX(-${theme.spacers.size8});
  }
`

const radioGroupWrapperNewCompactStyles = ({ theme, labelWidth }: { theme: Theme; labelWidth?: Maybe<string> }) => `
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & > div {
    width: 100%;
  }
  & > label {
    width: ${labelWidth || '30%'};
    min-width: ${labelWidth || '30%'};
    text-align: left;
    font-size: 12px;
    padding-right: ${theme.spacing(2)};
  }
`

// Handles horizontal/vertical padding dependant on layout
export const RadioSpacer = styled(Box)<{ variant?: Variant }>(
  ({ theme }) => `
  width: 100%;
  flex: 1;
  margin-left: ${rem(theme.display.spacingIncrement * 2)};
  & > label {
    border-radius: ${theme.display.borderRadius};
  }

  &:first-of-type {
    margin-left: 0;
  }

  &.column {
    cursor: pointer;
    margin-top: -1px;
    margin-left: 0;
    & > label {
      border-radius: 0;
    }

    &:first-of-type > label {
        border-top-left-radius: ${theme.display.borderRadius};
        border-top-right-radius: ${theme.display.borderRadius};
      }
    }

    &:last-of-type > label {
      border-bottom-left-radius: ${theme.display.borderRadius};
      border-bottom-right-radius: ${theme.display.borderRadius};
    }
  }
`,
)

export const RadioPillSpacer = styled(Box)<{ variant?: Variant }>(
  ({ theme }) => `
  width: 100%;
  flex: 1;
  margin-top: ${theme.spacing(1)};
  margin-left: ${rem(theme.display.spacingIncrement * 2)};
  & > label {
    border-radius: ${theme.display.borderRadius};
  }

  &:first-of-type {
    margin-left: 0;
  }

  &.column {
    cursor: pointer;
    margin-top: -1px;
    margin-left: 0;
    & > label {
      border-radius: 0;
      border-top-left-radius: ${theme.display.borderRadius};
      border-top-right-radius: ${theme.display.borderRadius};
      border-bottom-left-radius: ${theme.display.borderRadius};
      border-bottom-right-radius: ${theme.display.borderRadius};
    }
  }
`,
)
