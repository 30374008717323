import React, { useMemo } from 'react'
import { Box } from '@mui/material'

import { testHandle } from '@acre/utils'
import { Maybe } from '@acre/graphql'

import { ItemCardData } from '../../utils/types'
import { ColourCardTypes } from '../ColourCard/ColourCard.types'
import ListItem from './ListItem'
import { CardHeader, Title } from './ColouredItemCard.styles'

type Props = {
  title?: string
  options?: JSX.Element
  data?: ItemCardData[]
  colourVariant?: ColourCardTypes
  checkbox?: Maybe<JSX.Element>
  id?: string
}

const CardContent = ({ title, options, data, colourVariant, checkbox, id }: Props) => {
  const renderList = useMemo(() => {
    return data?.map((item) => {
      return <ListItem id={item.id} key={item.id} data={item} colourVariant={colourVariant} />
    })
  }, [colourVariant, data])

  return (
    <>
      <CardHeader>
        <Box display="flex" alignItems="center">
          {checkbox} {/* for the deposits cards only */}
          <Title data-testid={id ? testHandle(`${id}-Title`) : null} colourVariant={colourVariant}>
            {title}
          </Title>
        </Box>
        {options}
      </CardHeader>
      {renderList}
    </>
  )
}

export default CardContent
