import { Chip as MuiChip, ChipProps as MuiChipProps, styled, SvgIcon, SvgIconProps } from '@mui/material'
import { X } from 'react-feather'

export interface ChipProps extends MuiChipProps {}

const DeleteIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <X />
  </SvgIcon>
)

const ChipBase = styled(MuiChip)(({ onClick }) => ({
  ...(onClick && {
    cursor: 'pointer',
  }),
}))

const Chip = (props: ChipProps) => <ChipBase {...props} deleteIcon={props.onDelete && <DeleteIcon />} />

export default Chip
