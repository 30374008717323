import { createContext, type ProviderProps } from 'react'
import type { FieldValues } from 'react-hook-form'
import type { FormCustomContext, ComponentMapBase, InferPropsMap } from '../../types/schema-base'
import type { AnyObjectSchema } from 'yup'
import type { FormSchema, FormComponentSchema } from '../../types/schema'

export const FormSchemaProvider = <
  TContext extends FormCustomContext = FormCustomContext,
  TFieldValues extends FieldValues = FieldValues,
  TComponentMap extends Partial<ComponentMapBase> = ComponentMapBase,
>(
  props: ProviderProps<FormSchemaContext<TContext, TFieldValues, TComponentMap>>,
) => {
  const ContextProvider = FormSchemaContext.Provider as React.Context<
    FormSchemaContext<TContext, TFieldValues, TComponentMap>
  >['Provider']

  return <ContextProvider {...props} />
}

export interface FormSchemaContext<
  TContext extends FormCustomContext = FormCustomContext,
  TFieldValues extends FieldValues = FieldValues,
  TComponentMap extends Partial<ComponentMapBase> = ComponentMapBase,
> {
  schema: FormSchema<AnyObjectSchema, AnyObjectSchema, TComponentMap, TContext, TFieldValues> | undefined
  blockIndex: Record<string, FormComponentSchema<TFieldValues, InferPropsMap<TComponentMap>, TContext>> | undefined
  context?: TContext
}

export const FormSchemaContext = createContext<FormSchemaContext>({
  schema: undefined,
  blockIndex: undefined,
  context: {},
})
