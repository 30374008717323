import React, { SyntheticEvent, useCallback } from 'react'
import { styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'

import useFieldSlotProps from '../Field/hooks/useFieldSlotProps'
import TextField, { TextFieldProps } from '../TextField'
import { getNumberFieldUtilityClass } from './numberFieldClasses'

export type NumberFieldValue<ValueIsNumber extends boolean = true> =
  | (ValueIsNumber extends true ? number : string)
  | null

interface NumberInputElement<ValueIsNumber extends boolean = true> extends Omit<HTMLInputElement, 'value'> {
  value?: NumberFieldValue<ValueIsNumber>
}

export interface NumberChangeEvent<ValueIsNumber extends boolean = true> extends SyntheticEvent<HTMLInputElement> {
  target: EventTarget & NumberInputElement<ValueIsNumber>
}

export interface NumberFieldProps<ValueIsNumber extends boolean = true>
  extends Omit<TextFieldProps<number>, 'onChange' | 'value'> {
  onChange?: React.EventHandler<NumberChangeEvent<ValueIsNumber>>
  value?: NumberFieldValue<ValueIsNumber>
  valueAsNumber?: ValueIsNumber
}

const NumberFieldRoot = styled(TextField, {
  name: 'NumberField',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})<NumberFieldProps>`` as typeof NumberField

const useUtilityClasses = (ownerState: Partial<NumberFieldProps>) => {
  const slots = {
    root: ['root', ownerState.layout || 'row', ownerState.size || 'medium'],
    field: ['field'],
    label: ['label'],
    input: ['input'],
    helperText: ['helperText'],
    errorText: ['errorText'],
  }

  return unstable_composeClasses(slots, getNumberFieldUtilityClass, ownerState.classes)
}

const _NumberField = <ValueIsNumber extends boolean = true>(props: NumberFieldProps<ValueIsNumber>) => {
  const { className, classes, onChange, valueAsNumber = true, ...rootProps } = props

  const slotClasses = useUtilityClasses({ classes, layout: rootProps.layout, size: rootProps.size })

  const { labelProps, helperTextProps, errorProps } = useFieldSlotProps(slotClasses, props)

  const numberInputOnWheelPreventChange = useCallback((e: React.WheelEvent<HTMLInputElement>) => {
    const el = e.target as HTMLInputElement
    // Prevent the input value change
    el.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      el.focus()
    }, 0)
  }, [])

  const handleChange = useCallback<React.EventHandler<NumberChangeEvent<ValueIsNumber>>>(
    (e) => {
      if (onChange) {
        const target = { ...e.target, name: e.target.name }

        if (valueAsNumber && Number.isNaN(e.target.valueAsNumber)) {
          onChange({ ...e, target: { ...target, value: null as NumberFieldValue<ValueIsNumber> } })
        } else {
          onChange({
            ...e,
            target: {
              ...target,
              value: e.target[valueAsNumber ? 'valueAsNumber' : 'value'] as NumberFieldValue<ValueIsNumber>,
              valueAsNumber: e.target.valueAsNumber,
            },
          })
        }
      }
    },
    [onChange, valueAsNumber],
  )

  return (
    <NumberFieldRoot
      {...rootProps}
      className={classnames(slotClasses.root, className)}
      inputProps={{
        ...rootProps.inputProps,
        type: 'number',
        pattern: '[0-9]*',
        inputMode: 'numeric',
        className: slotClasses.input,
        onWheel: numberInputOnWheelPreventChange,
      }}
      InputProps={{
        ...rootProps.InputProps,
        className: classnames(slotClasses.field, rootProps.InputProps?.className),
      }}
      InputLabelProps={labelProps}
      FormHelperTextProps={helperTextProps}
      ErrorTextProps={errorProps}
      onChange={handleChange}
    />
  )
}

const NumberField = React.memo(_NumberField) as typeof _NumberField

export default NumberField
