import React, { Key, useCallback } from 'react'
import type { ArrayPath, FieldValues } from 'react-hook-form'

import type { FieldArraySchema, FormComponentSchema } from '../../types/schema'
import SchemaFormComponent from './SchemaFormComponent'
import Field from '../Field'
import type { AnyObjectSchema } from 'yup'
import type { FieldTypes } from '../../types/types'
import type { FieldArrayRenderFn } from '../../types/fieldArray'
import type { ComponentMapBase, ComponentSchemaPropsMapBase, FormCustomContext } from '../../types/schema-base'

export interface SchemaFieldArrayComponentProps<
  TFieldValues extends FieldValues,
  TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
> extends FieldArraySchema<TFieldValues> {
  name: TName
}

const _SchemaFieldArrayComponent = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends FormCustomContext = FormCustomContext,
>(
  props: SchemaFieldArrayComponentProps<TFieldValues>,
) => {
  const renderFieldArray = useCallback<FieldArrayRenderFn<TFieldValues>>(
    ({ name, index }) => {
      return props.parts.map(({ name: partName, ...component }) => (
        <SchemaFormComponent<TFieldValues, ArrayPath<TFieldValues>, ComponentMapBase, TContext>
          key={partName as Key}
          {...(component as FormComponentSchema<
            TFieldValues,
            ComponentSchemaPropsMapBase,
            TContext,
            ArrayPath<TFieldValues>
          >)}
          name={`${name}.${index}.${String(partName)}` as ArrayPath<TFieldValues>}
        />
      ))
    },
    [props.parts],
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Field<FieldTypes, any, AnyObjectSchema, TFieldValues> {...props} render={renderFieldArray} />
}

const SchemaFieldArrayComponent = React.memo(_SchemaFieldArrayComponent) as typeof _SchemaFieldArrayComponent

export default SchemaFieldArrayComponent
