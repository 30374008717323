import React from 'react'
import { Button } from '@mui/material'
import type { FieldValues } from 'react-hook-form'
import useFieldArrayComponent from '../../hooks/useFieldArrayComponent'
import FieldSet from '../FieldSet'
import { FieldArrayProps } from './FieldArray.types'

// TODO: Figure out how to make this configurable

const _FieldArray = <TFieldValues extends FieldValues>({
  name,
  highlight = false,
  render: renderProp,
}: FieldArrayProps<TFieldValues>) => {
  const { fields, render, handleAddClick, handleRemoveClick } = useFieldArrayComponent<TFieldValues>({
    name,
    render: renderProp,
  })

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <FieldSet highlight={highlight}>{render({ field, index })}</FieldSet>

          {index !== 0 && (
            <Button size="small" onClick={handleRemoveClick(index)}>
              - remove address
            </Button>
          )}

          {fields.length - 1 === index && (
            <Button size="small" onClick={handleAddClick}>
              + Add previous address
            </Button>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

const FieldArray = React.memo(_FieldArray) as typeof _FieldArray

export default FieldArray
