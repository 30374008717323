import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface FieldArrayClasses {
  root: string
}

export type FieldArrayClassKey = keyof FieldArrayClasses

export function getFieldArrayUtilityClass(slot: string) {
  return generateUtilityClass('FieldArray', slot)
}

const fieldArrayClasses: FieldArrayClasses = generateUtilityClasses('FieldArray', ['root'])

export default fieldArrayClasses
