import React, {
  ChangeEvent,
  FocusEvent,
  MouseEvent as ReactMouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import classNames from 'classnames'
import { format, isValid } from 'date-fns'
import { FormattedMessage, useIntl } from 'react-intl'

import { isNil, ISODateString } from '@acre/utils'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import HelperText from '../HelperText'
import { Legend } from '../Label'
import MatomoWrapper from '../MatomoWrapper'
import { isNumberWithinParameters, ShortcutButtonConfig, shortcutButtonsConfig } from './DateInput.helpers'
import { LabelAndInputWrapper } from '../../styles/form-control.styles'
import { DateFieldset, DateInputField, DateInputSection, DateShortcutButton } from './DateInput.styles'

export type DateInputProps = {
  id: string
  label?: string | ReactElement
  value?: Date | ISODateString
  error?: boolean
  message?: ReactNode
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onClick?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void
  disabled?: boolean
  variant?: Variant
  secondaryText?: ReactNode
  includeDateShortcutButtons?: Boolean
  isMissing?: boolean
  customShortcuts?: ShortcutButtonConfig[]
}
const DateSeperator = () => <span style={{ display: 'flex', alignItems: 'center' }}>/</span>

enum DateSegment {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

type InputConfiguration = {
  requiredLength: number
  nextInput?: React.RefObject<HTMLInputElement>
  set: React.Dispatch<string>
  isValid: (value: string | null) => boolean
}

const DateInput = ({
  id,
  label,
  value,
  error = false,
  message,
  onChange,
  onBlur,
  onFocus,
  onClick,
  disabled: disabledProp,
  variant = 'default',
  secondaryText,
  isMissing = false,
  includeDateShortcutButtons = false,
  customShortcuts,
}: DateInputProps) => {
  const intl = useIntl()
  const theme = useTheme()

  const disabled = useFieldDisabledState(disabledProp)

  // Internal day, month, year
  const [day, setDay] = useState<string | null>(null)
  const [month, setMonth] = useState<string | null>(null)
  const [year, setYear] = useState<string | null>(null)

  // Internal refs for dayInput, monthInput, yearInput
  const dayInput = useRef<HTMLInputElement>(null)
  const monthInput = useRef<HTMLInputElement>(null)
  const yearInput = useRef<HTMLInputElement>(null)

  // Utils
  const _formatMessage = (id: string) => intl.formatMessage({ id })
  const className = classNames({ disabled })

  // Set the values in state from the `value` prop
  const setStateFromValue = (value: DateInputProps['value']) => {
    const dt = new Date(String(value))

    /**
     * Represents the date and time value of `dt` in milliseconds, adjusted for the timezone offset.
     */
    const dtDateOnly = dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000

    setMonth(format(new Date(dtDateOnly), 'MM'))
    setDay(format(new Date(dtDateOnly), 'dd'))
    setYear(format(new Date(dtDateOnly), 'yyyy'))
  }

  // When value prop changes, and also on initial mount, set the value
  useEffect(() => {
    if (value && value !== formattedValue) {
      setStateFromValue(value)
    } else if (!value) {
      setMonth(null)
      setDay(null)
      setYear(null)
    }
  }, [value])

  // A map of each of the parts of the component and how they should behave
  const inputConfig: { [key in DateSegment]: InputConfiguration } = useMemo(
    () => ({
      [DateSegment.DAY]: {
        requiredLength: 2,
        nextInput: monthInput,
        set: setDay,
        isValid: (value: string | null) => isNumberWithinParameters(value, 2, 31),
      },
      [DateSegment.MONTH]: {
        requiredLength: 2,
        nextInput: yearInput,
        set: setMonth,
        isValid: (value: string | null) => isNumberWithinParameters(value, 2, 12),
      },
      [DateSegment.YEAR]: {
        requiredLength: 4,
        set: setYear,
        isValid: (value: string | null) => isNumberWithinParameters(value, 4),
      },
    }),
    [],
  )

  const allValuesEmpty = useMemo(() => day === '' && month === '' && year === '', [day, month, year])
  const allValuesNull = useMemo(() => day === null && month === null && year === null, [day, month, year])

  // Keep an internal memoised value of the formatted (onChange) date
  const formattedValue = useMemo(() => {
    // when all values empty send empty string, as the
    // formatted date would result in an invalid Date string
    if (allValuesEmpty) {
      return ''
    }
    let date =
      !isNil(year) && !isNil(month) && !isNil(day) ? new Date(Number(year), Number(month) - 1, Number(day)) : null
    if (year?.length === 1) {
      date =
        !isNil(year) && !isNil(month) && !isNil(day) ? new Date(Number(year), Number(month) - 1, Number(day)) : null
    }

    const valueToDisplay = !isNil(date) && isValid(new Date(date)) ? format(new Date(String(date)), 'yyyy-MM-dd') : null

    return valueToDisplay
  }, [day, month, year])

  // Keep an internal memoised value of if the date for onChange is valid
  const allValuesValid = useMemo(() => {
    if (allValuesEmpty) {
      return true
    }
    return (
      inputConfig[DateSegment.DAY].isValid(day) &&
      inputConfig[DateSegment.MONTH].isValid(month) &&
      inputConfig[DateSegment.YEAR].isValid(year) &&
      year!.length === 4
    )
  }, [day, month, year])

  // When the value changes and/or becomes valid, fire onChange
  useEffect(() => {
    if (!allValuesNull && allValuesValid && onChange) {
      onChange({ target: { value: formattedValue } } as ChangeEvent<HTMLInputElement>)
    }
  }, [allValuesValid, formattedValue])

  // Figure out which field just changed, based on the event target and the refs
  const determineField = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target === dayInput.current) return DateSegment.DAY
    if (event.target === monthInput.current) return DateSegment.MONTH
    if (event.target === yearInput.current) return DateSegment.YEAR
  }

  // onChange for the inputs
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      const field = determineField(event)
      const input = field ? inputConfig[field] : null

      // If the value is valid (or empty) then update state
      if (input && (input.isValid(value) || value === '')) {
        input.set(value)

        // If value is complete, focus the next input
        if (value.length === input.requiredLength && input.nextInput?.current) {
          input.nextInput.current.focus()
        }
      }
    },
    [inputConfig],
  )

  const renderDateShortcutButtons = () => {
    const shortcutsConfig = customShortcuts || shortcutButtonsConfig
    const shortcutButtons = shortcutsConfig.map((item) => (
      <DateShortcutButton
        data-testid={`${testHandle(item.id)}Button`}
        type="button"
        key={item.id}
        onClick={() => {
          setStateFromValue(item.date)
        }}
      >
        <FormattedMessage id={item.messageId} />
      </DateShortcutButton>
    ))
    return (
      <Box ml={`calc(30% + ${theme.spacers.size8})`} mt={theme.spacers.size8}>
        {shortcutButtons}
      </Box>
    )
  }

  return (
    <MatomoWrapper
      onChange={onChange}
      id={id}
      eventType="dateInputChange"
      trackEventTrigger="onBlur"
      style={{ width: undefined }}
    >
      <DateFieldset data-testid={testHandle(id)}>
        <LabelAndInputWrapper variant={variant}>
          {label && (
            <Legend
              text={label}
              isDisabled={false}
              secondaryText={secondaryText}
              isMissing={isMissing}
              variant={variant}
            />
          )}
          <DateInputField className={className} error={error} variant={variant}>
            <DateInputSection
              data-testid={`${testHandle(id)}DD`}
              placeholder="DD"
              value={day || ''}
              onChange={handleChange}
              aria-label={_formatMessage('generic.day')}
              maxLength={2}
              disabled={disabled}
              ref={dayInput}
              onFocus={onFocus}
              onClick={onClick}
              onBlur={onBlur}
              type="number"
              pattern="\d*"
            />
            <DateSeperator />
            <DateInputSection
              data-testid={`${testHandle(id)}MM`}
              ref={monthInput}
              placeholder="MM"
              value={month || ''}
              onChange={handleChange}
              aria-label={_formatMessage('generic.month')}
              maxLength={2}
              disabled={disabled}
              onFocus={onFocus}
              onClick={onClick}
              onBlur={onBlur}
              type="number"
              pattern="\d*"
            />
            <DateSeperator />
            <DateInputSection
              data-testid={`${testHandle(id)}YYYY`}
              ref={yearInput}
              placeholder="YYYY"
              value={year || ''}
              onChange={handleChange}
              aria-label={_formatMessage('generic.year')}
              maxLength={4}
              disabled={disabled}
              onFocus={onFocus}
              onClick={onClick}
              onBlur={onBlur}
              type="number"
              pattern="\d*"
            />
          </DateInputField>
        </LabelAndInputWrapper>

        {message && <HelperText id={id} message={message || ''} textType={HELPER_TYPE_ERROR} variant={variant} />}
        {isMissing && (
          <HelperText
            id={`${id}Helper`}
            message={_formatMessage('errors.missingFieldRequired')}
            textType={HELPER_TYPE_ERROR}
            variant={variant}
          />
        )}
        {includeDateShortcutButtons && renderDateShortcutButtons()}
      </DateFieldset>
    </MatomoWrapper>
  )
}

export default withDisabled(DateInput)
