import { generateUtilityClass, generateUtilityClasses } from '@mui/material'

export interface FieldArrayItemClasses {
  root: string
}

export type FieldArrayItemClassKey = keyof FieldArrayItemClasses

export function getFieldArrayItemUtilityClass(slot: string) {
  return generateUtilityClass('FieldArrayItem', slot)
}

const fieldArrayItemClasses: FieldArrayItemClasses = generateUtilityClasses('FieldArrayItem', ['root'])

export default fieldArrayItemClasses
