import React, { useCallback, useRef, useState } from 'react'

import testHandle from '../../utils/testHandle'
import Button from '../ButtonNewest'

const DEFAULT_VALUE = ''

export type InputTypes = 'text' | 'date'

export interface UseProtectedTextFieldProps<T = string | Date> {
  name: string
  inputRef?: React.MutableRefObject<HTMLInputElement | null>
  initialValue?: T
  loadData?: () => Promise<T | null>
  disabled?: boolean
  hideControls?: boolean
  dataTestId?: string
}

export function useProtectedTextField<T extends string | Date | number>({
  name,
  inputRef,
  initialValue,
  loadData,
  disabled,
  hideControls,
  dataTestId,
}: UseProtectedTextFieldProps<T>) {
  const currentValue = inputRef?.current ? inputRef.current.value : initialValue
  const value = currentValue || DEFAULT_VALUE

  const dataTestIdButton = dataTestId + 'Button'

  const [protectedValue, setProtectedValue] = useState<T | string | Date>(
    inputRef?.current ? inputRef.current.value : '',
  )

  const hasLoadedDataRef = useRef(Boolean(String(protectedValue)?.length))

  const valueLength = String(value).length

  const [isProtected, setIsProtected] = useState(Boolean(valueLength))
  const [isEditMode, setIsEditMode] = useState(!valueLength)

  const handleIsProtectedToggle = useCallback(async () => {
    if (loadData) {
      const result = await loadData()

      if (result) {
        setProtectedValue(result)
      }

      hasLoadedDataRef.current = true
    }

    setIsProtected((oldIsProtected) => !oldIsProtected)
  }, [loadData])

  const handleIsEditModeClick = useCallback(async () => {
    if (loadData) {
      const result = await loadData()

      if (result) {
        setProtectedValue(result)
      }

      hasLoadedDataRef.current = true
    }

    setIsEditMode(true)
    setIsProtected(false)
  }, [loadData])

  const controlButtons = [
    <Button aria-controls={name} key="show" variant="secondary" sx={{ ml: 1 }} onClick={handleIsProtectedToggle}>
      {isProtected ? 'Show' : 'Hide'}
    </Button>,
  ]

  if (!disabled) {
    controlButtons.push(
      <Button
        aria-controls={name}
        key="edit"
        variant="secondary"
        sx={{ ml: 1, minWidth: 'auto' }}
        onClick={handleIsEditModeClick}
        data-testid={testHandle(dataTestIdButton)}
      >
        Edit
      </Button>,
    )
  }

  return {
    isProtected,
    isEditMode,
    controlButtons: !hideControls ? controlButtons : null,
    obfuscatedValue: initialValue,
    protectedValue,
    setProtectedValue,
  }
}
