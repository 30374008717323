import React from 'react'
import { FormSchemaContext } from '../components/data/FormSchemaProvider'
import { ComponentMapBase, FormCustomContext } from '../types/schema-base'
import { FieldValues } from 'react-hook-form'

const useFormSchema = <
  TContext extends FormCustomContext = FormCustomContext,
  TFieldValues extends FieldValues = FieldValues,
  TComponentMap extends Partial<ComponentMapBase> = ComponentMapBase,
>() => React.useContext(FormSchemaContext as React.Context<FormSchemaContext<TContext, TFieldValues, TComponentMap>>)

export default useFormSchema
