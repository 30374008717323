import envConfig from '@acre/config'

import createAxiosInstance from '../utils/createAxiosInstance'

const axiosInstance = createAxiosInstance(
  {
    baseURL: envConfig.CLIENT_PORTAL_OATHKEEPER_PUB_API_URL,
  },
  {
    useCpApiKey: true,
    useLutherInterceptor: true,
  },
)

export default axiosInstance
