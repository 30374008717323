import { ReactElement, useState } from 'react'
import { Box } from '@mui/material'
import { transparentize } from 'polished'

import theme from '../../theme/theme.mui'
import Button from '../ButtonNewest'
import FeatherIcon, { IconName } from '../FeatherIcon'
import { WarningBox } from './GlobalWarning.styles'

type Props = {
  messageText: String
  background?: string
  colour?: string
  buttonText?: String
  buttonAction?: () => void
  icon?: ReactElement
}

export const GlobalWarning = ({
  messageText,
  buttonText,
  buttonAction,
  background = theme.colours.new.yellow1,
  colour = theme.colours.new.yellow2,
  icon,
}: Props) => {
  const [dismissed, setDismissed] = useState<boolean>(false)

  if (dismissed) {
    return null
  }

  return (
    <WarningBox background={background}>
      <Box color={colour} display="flex" flexGrow="1" alignItems="center" lineHeight={1.5} mr={4}>
        {icon && (
          <Box mr={4} display="flex" alignItems="center">
            {icon}
          </Box>
        )}
        {messageText}
      </Box>
      {buttonText && buttonAction && (
        <Button
          variant="outlined"
          disabled={false}
          sx={{
            color: colour,
            borderColor: colour,
            '&:hover': {
              background: transparentize(theme.opacity.op90, colour),
              borderColor: transparentize(theme.opacity.op20, colour),
              color: transparentize(theme.opacity.op20, colour),
            },
          }}
          onClick={buttonAction}
        >
          {buttonText}
        </Button>
      )}
      <Box ml={2} mr={4}>
        <Button variant="text" onClick={() => setDismissed(true)} disabled={false} sx={{ color: colour }}>
          <FeatherIcon name={IconName.X} />
        </Button>
      </Box>
    </WarningBox>
  )
}

export default GlobalWarning
